import React from "react";

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-content">
        <a
          href="https://aldealista.com/"
          target="_blank"
          className="footer-link"
          rel="noopener noreferrer"
        >
          <i className="fa fa-globe"></i>
          <span>Aldealista</span>
        </a>

        <a
          href="https://play.google.com/store/apps/details?id=com.aldealista"
          target="_blank"
          className="footer-link"
          rel="noopener noreferrer"
        >
          <i className="fa fa-android"></i>
          <span>Descarga para Android</span>
        </a>

        <a
          href="https://apps.apple.com/es/app/aldealista/id1658806105"
          target="_blank"
          className="footer-link"
          rel="noopener noreferrer"
        >
          <i className="fa fa-apple"></i>
          <span>
            Descarga para <br />
            iOS
          </span>
        </a>
      </div>
    </footer>
  );
}

export default Footer;
