import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import ImageGallery from "react-image-gallery";
import HeaderProfile from "./HeaderProfile";
import ReactPlayer from "react-player";
import { ProfileContext } from "../../../context/ContextProfile";
import { Container, Row, Col } from "react-bootstrap";
import axios from "axios";
import DOMPurify from "dompurify";
import "react-image-gallery/styles/css/image-gallery.css";

function SingleImage() {
  const {
    village,
    modifyVillage,
    images,
    modifyImages,
    modifyIsImageURL,
    image,
  } = useContext(ProfileContext);

  const [isGallery, setIsGallery] = useState(true);
  const [slideImages, setSlideImages] = useState(false);
  const navigate = useNavigate(); // Use useNavigate from React Router v6

  useEffect(() => {
    if (!images.path) {
      const urlPath = window.location.pathname;
      const segments = urlPath.split("/");
      const yourSegment = segments[2]; // Assuming the segment is always in this position
      const sanitizedSegment = DOMPurify.sanitize(yourSegment);

      // axios call to retrieve village and info image
      axios({
        method: "get",
        url: "https://api.aldealista.com/manager/getProfileFromImage",
        params: {
          pictureUID: sanitizedSegment,
        },
      })
        .then((res) => {
          if (res.data.errorCode !== 0) {
            navigate("/"); // Use navigate to redirect
          }
          modifyVillage(res.data);
          modifyImages(res.data.currentPicture);
          modifyIsImageURL(true);
        })
        .catch((error) => {
          console.error("Error fetching content:", error);
          navigate("/"); // Redirect to the home page on error
        });
      return;
    }

    if (images.isVideo === 1) {
      setSlideImages([]);
      return;
    }

    const preSlide = images.path.map((imgPath) => ({
      original: `https://aldealista.com/p/${imgPath}`,
      thumbnail: `https://aldealista.com/p/${imgPath}`,
      caption: "",
    }));

    setSlideImages(preSlide);
  }, [images, navigate, modifyVillage, modifyImages, modifyIsImageURL]);

  return (
    <>
      {slideImages && images.path && (
        <div className="slide-container">
          <HeaderProfile />
          {images.isVideo === 0 ? (
            <Row style={{ margin: 0 }}>
              <Col xs={12} style={{ padding: 0 }}>
                <div className="container-borders">
                  {isGallery ? (
                    <ImageGallery
                      showPlayButton={false}
                      items={slideImages}
                      showThumbnails={false}
                      autoPlay={false}
                      className="container-borders"
                      lazyLoad={true}
                      showFullscreenButton={false}
                    />
                  ) : (
                    <img
                      src={`https://aldealista.com/p/${images.path[0]}`}
                      alt="."
                      className="main-image"
                      style={{ width: "100%", height: "auto" }}
                    />
                  )}
                </div>
              </Col>
            </Row>
          ) : (
            <Row style={{ margin: 0 }}>
              <Col xs={12} style={{ padding: 0 }}>
                <div className="container-borders">
                  <div className="player-wrapper">
                    <ReactPlayer
                      className="react-player"
                      url={images.path[0]}
                      width="100%"
                      height="100%"
                    />
                  </div>
                </div>
              </Col>
            </Row>
          )}

          <Row className="description-row">
            <Col xs={12}>
              <p className="description">
                <span className="description-title">{images.title}</span>
              </p>
            </Col>
          </Row>

          {images.description && (
            <Row className="description-row">
              <Col xs={12}>
                <p className="description">{images.description}</p>
              </Col>
            </Row>
          )}

          {images.url1 && (
            <Row className="description-row">
              <Col xs={12}>
                <p className="description">
                  <a
                    href={images.url1}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {images.url1}
                  </a>
                </p>
              </Col>
            </Row>
          )}

          {images.url2 && (
            <Row className="description-row">
              <Col xs={12}>
                <p className="description">
                  <a
                    href={images.url2}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {images.url2}
                  </a>
                </p>
              </Col>
            </Row>
          )}

          {images.url3 && (
            <Row className="description-row">
              <Col xs={12}>
                <p className="description">
                  <a
                    href={images.url3}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {images.url3}
                  </a>
                </p>
              </Col>
            </Row>
          )}
        </div>
      )}
    </>
  );
}

export default SingleImage;
