import React, { useContext } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { ProfileContext } from "../../../context/ContextProfile";
import { useNavigate } from "react-router-dom";

const HeaderProfile = () => {
  const navigate = useNavigate(); // Use useNavigate instead of useHistory

  const {
    village,
    modifyVillage,
    images,
    modifyImages,
    modifyIsImageURL,
    isImageURL,
  } = useContext(ProfileContext);

  const navigateToProfile = () => {
    if (isImageURL) {
      navigate("/" + village.code); // Use navigate to change routes
    } else {
      window.history.pushState({}, "", `/${village.code}`);
    }
    modifyImages({});
  };

  return (
    <>
      {village.villageData && (
        <Container
          className="profile-single-container"
          style={{ marginTop: "20px", marginBottom: "20px" }}
        >
          <Row style={{ margin: 0 }}>
            <Col
              xs={12}
              className="container-single-image"
              onClick={navigateToProfile}
              style={{
                padding: 0,
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={
                  "https://aldealista.com/p/" +
                  village.villageData.profilePicture
                }
                alt="."
                className="profile-single-image"
                style={{
                  alignItems: "center",
                  borderRadius: "50%",
                  display: "flex",
                  float: "left",
                  height: "75px !important",
                  justifyContent: "center",
                  marginLeft: "20px",
                  marginTop: "5px",
                  width: "75px !important",
                  objectFit: "cover",
                }}
              />
              <p className="title-single" style={{ marginTop: "0px" }}>
                {village.villageData.displayName}
              </p>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

export default HeaderProfile;
