import React from "react";
import { Container } from "reactstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Navigation } from "swiper";
import "../../node_modules/swiper/swiper.scss";
import "../../node_modules/swiper/components/navigation/navigation.scss";
import "../../node_modules/swiper/components/pagination/pagination.scss";
import HeroIMG from "../assets/images/hero.jpg";
import "../languages/i18n.js";
import { useTranslation } from "react-i18next";

SwiperCore.use([Autoplay, Navigation]);

export default function Hero() {
  const { t } = useTranslation();

  return (
    <>
      <section
        className="swiper-slider-hero position-relative d-block vh-100"
        id="hero"
      >
        <Swiper
          slidesPerView={1} // Show only one slide
          allowTouchMove={false} // Disable sliding by touch or mouse
          loop={false} // Disable infinite looping
          navigation={false} // Disable navigation arrows
          pagination={false} // Disable pagination (dots)
        >
          <SwiperSlide className="d-flex align-items-center overflow-hidden">
            <div
              className="slide-inner slide-bg-image d-flex align-items-center"
              style={{
                background: `url(${HeroIMG}) center center`,
                backgroundSize: "cover",
              }}
            >
              <div className="bg-overlay"></div>
              <Container>
                <div className="row justify-content-center">
                  <div className="col-12">
                    <div className="title-heading text-center">
                      <h1 className="display-5 text-white title-dark fw-bold mb-4">
                        {t("about.bannerTitle")}
                      </h1>
                      <p
                        className="para-desc mx-auto text-white-50"
                        style={{ color: "rgba(255, 255, 255, 1) !important" }}
                      >
                        {t("about.bannerSubtitle")}
                      </p>

                      {/*
                      <div className="mt-4 pt-2">
                        <Link2 to="#" className="btn btn-primary">
                          Contact us
                        </Link2>
                      </div>
                      */}
                    </div>
                  </div>
                </div>
              </Container>
            </div>
          </SwiperSlide>
        </Swiper>
      </section>
    </>
  );
}
