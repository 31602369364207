export const TRANSLATIONS_ES = {
  menu: {
    about: "Nuestra historia",
    services: "¿Qué hacemos?",
    ecosystem: "Aldealista Ecosystem",
    media: "Prensa y medios",
    app: "La App",
    contact: "Contacto",
    language: "Idioma",
  },
  about: {
    bannerTitle: "Aldealista: hacemos visible lo invisible",
    bannerSubtitle:
      "Rural Match contra la despoblación: conectando el presente con el futuro del campo",
    bannerFeatures1: "Conectamos a las personas con los pueblos",
    bannerFeaturesSub1:
      "Facilitamos la integración de nuevos residentes en comunidades rurales, brindando oportunidades para vivir, trabajar y prosperar.",
    bannerFeatures2: "Somos el movimiento del Renacimiento Rural",
    bannerFeaturesSub2:
      "Revitalizamos las áreas rurales, promoviendo un desarrollo sostenible que atrae a personas, empresas y talento hacia un futuro próspero en el campo.",
    bannerFeatures3: "Porque los pueblos no pueden desaparecer",
    bannerFeaturesSub3:
      "Cada pueblo cuenta una historia. Luchamos para que esa historia siga viva, impulsando soluciones que aseguren su supervivencia y crecimiento.",
    mainTitle: "Nuestra historia",
    mainSubtitle: "Nacimos de un deseo, de un sueño, de un encargo.",
    header1: "Haz algo para salvar estos pueblos",
    text1:
      "Estas fueron las palabras que su abuela, con 102 años de sabiduría, le dijo antes de partir. Era plena pandemia, y Juan Carlos Pérez, entonces residente en Noruega, decidió regresar a su aldea en Galicia para fundar Aldealista. No fue una decisión cualquiera, fue un llamado: salvar lo rural, revitalizar lo que parecía condenado al olvido.",
    text2:
      "Aldealista no es solo una plataforma; es el motor que impulsa el renacer del ecosistema rural. Conectamos las potencialidades de cada territorio, dando valor a sus recursos y atrayendo a personas de todas partes del mundo. Nuestro objetivo: que la gente elija quedarse, trabajar y vivir en estos lugares que aún tienen tanto que ofrecer.",
    text3:
      "Innovación, creatividad y un impacto real son el corazón de nuestras propuestas. Porque creemos que el futuro también se construye desde lo rural.",
    text4:
      "Hoy, Aldealista está presente en 52 países, uniendo a personas y comunidades. Estamos creando un nuevo futuro rural, lleno de oportunidades y vida.",
    ourTeam: "Nuestro equipo",
    ourTeamSubtitle: "Impulsores del Renacimiento Rural",
    team1: "Juan Carlos Pérez",
    team1Sub:
      "CEO - Socio fundador Grupo Rural Manager SL y VPF Scandinavia AS - Desarrollo IT",
    team2: "Juan Martín",
    team2Sub: "Socio fundador Grupo Rural Manager SL - Controller Aceleradora",
    team3: "María Valcarcel",
    team3Sub: "Marca",
    team4: "Migue Gil",
    team4Sub: "Plataforma Booking - Suscripción",
    team5: "Rocío Pérez",
    team5Sub: "Marketing Digital",
  },
  services: {
    mainTitle: "¿Qué hacemos?",
    mainSubtitle:
      "Luchamos contra la despoblación: nuestro compromiso con el mundo rural",
    title1: "Los pueblos invisibles: una realidad que no podemos ignorar",
    text1:
      "En España, de los 8.131 municipios, 6.827 cuentan con menos de 5.000 habitantes. Son pueblos invisibles, pero esenciales.",
    text2:
      "¿Sabías que el 16% de la población rural alimenta al otro 84% que vive en las ciudades? Esta es la paradoja de un mundo que depende del campo, pero lo deja atrás.",
    text3:
      "En los municipios de menos de 5.000 habitantes, un preocupante 44% de las viviendas están vacías o solo se utilizan como segundas residencias. El campo se vacía mientras la ciudad crece.",
    text4:
      "Más del 80% de la población vive en menos del 20% del territorio. Este desequilibrio nos invita a repensar el futuro de nuestras tierras rurales.",
    text5: "No podemos esperar más.",
    text6: "Es el momento de impulsar el rural.",
  },
  ecosystem: {
    headerTitle: "Aldealista Ecosystem",
    headerSubtitle: "La clave para el renacimiento rural",
    text1:
      "Aldealista Ecosystem es más que una plataforma. Es una iniciativa completa que conecta soluciones innovadoras con las necesidades del mundo rural. A través de servicios interconectados y estrategias efectivas, estamos abordando tanto los desafíos como las oportunidades que existen en estas comunidades.",
    text2:
      "Nuestro objetivo no es solo atraer nuevos residentes y turistas a las áreas rurales, sino transformar estas regiones con un enfoque integral. Promovemos el desarrollo económico, impulsamos la creación de empleo, y trabajamos en mejorar infraestructuras y servicios esenciales para construir un futuro rural vibrante.",
    subHeader: "¿Qué buscamos lograr?",
    subHeaderText1:
      "Fortalecer la economía rural, impulsando nuevas oportunidades.",
    subHeaderText2:
      "Mejorar la calidad de vida de quienes eligen vivir y trabajar en estas áreas.",
    subHeaderText3:
      "Fomentar un desarrollo sostenible y equilibrado que beneficie tanto a las personas como al medio ambiente.",
    text3:
      "Con estos cinco pilares, Aldealista Ecosystem es la plataforma que conecta, descubre y hace crecer a las comunidades rurales, llevando progreso donde más se necesita.",
  },
  media: {
    mainHeader: "Prensa",
    subheaderTitle: "El impacto mediático en 2023: una huella significativa",
    subheaderTitleText:
      "En el año 2023, el valor estimado del impacto mediático de Aldealista Ecosystem ha alcanzado cifras sorprendentes: más de 850.920 € en medios impresos y digitales, y 106.603 € en Radio y Televisión. Estas cifras subrayan el alcance y la relevancia de nuestra misión en la revitalización del mundo rural.",
    subheaderClipping: "Clipping de prensa",
    subHeaderTextClipping:
      "Selección de noticias con la aparición de Aldealista, tanto en medios nacionales como internacionales, con un enlace directo a la fuente original en su idioma.",
    subHeaderAwards: "Reconocimientos y logros que impulsan nuestra misión",
    subHeaderAwardsText1:
      "Julio 2023: Finalistas en la categoría App en la prestigiosa IV Edición de los European Technology Awards, celebrada en el icónico Hotel Ritz de París.",
    subHeaderAwardsText2:
      "Noviembre 2023: La Agencia Gallega de Innovación (GAIN) nos ha reconocido como parte de su selección de casos de éxito innovadores.",
    subHeaderAwardsText3:
      "22 de Noviembre: Ganadores del programa “Que Vivan los Pueblos”, una iniciativa para startups comprometidas con el desarrollo rural, organizada por Yoigo y Unlimited.",
    subHeaderAwardsText4:
      "Mejor Empresa Innovadora 2023: Un galardón otorgado por la Confederación de Empresarios de Ourense, que destaca nuestro enfoque innovador.",
    subHeaderAwardsText5:
      "MadBlue Impact Summit: Ganadores en la categoría People & Social Impact, reafirmando nuestro compromiso con un impacto social positivo.",
    readMore: "Leer más",
  },
  app: {
    headerTitle: "La App",
    headerSubtitle: "Descubre tu pueblo",
    text1:
      "Aldealista es la aplicación de la España Rural Viva, que llega para empoderar y convertirse en La Resistencia del Rural. ¡Desliza y descubre tu pueblo!",
    featuresTitle: "¿Cómo funciona?",
    featuresSubtitle: "Domina en tres sencillos pasos la dinámica de la app:",
    featuresText1: "Selecciona tu área",
    featuresSubtext1:
      "Dinos que punto concreto de la geografía quieres explorar, o selecciona todo el territorio si estás indeciso",
    featuresText2: "Desliza",
    featuresSubtext2:
      "Desliza a izquierda o derecha como en las aplicaciones de citas que dices que nunca has utilizado, nuestro algoritmo inteligente aprenderá de tus preferencias",
    featuresText3: "Match",
    featuresSubtext3:
      "¡Creemos que hemos encontrado un pueblo perfecto para ti! Echa un vistazo a su perfil, descubre lo que tiene para ofrecer y prepárate para visitarlo",
    screenshotTitle: "Psst, echa un ojo a la app",
    screenshotSubtitle:
      "Traemos unas capturas a modo de sneak peek para que te vayas familiarizando con Aldealista.",
    metrics: "Métricas",
    metricsSubtitle: "Nuestro impacto en números, desde enero de 2023",
    users: "Usuarios",
    matches: "Matches",
    swipes: "Swipes",
  },
  contact: {
    header: "Contacto",
    headerSubtitle:
      "Estamos creando un futuro rural lleno de oportunidades, y queremos que formes parte de él. ¡Conéctate con nosotros y descubre cómo puedes vivir o trabajar en los pueblos que necesitan tu energía!",
    text1:
      "¿Te gustaría vivir en el rural? Estamos ayudando a personas como tú a encontrar su hogar ideal en áreas rurales que necesitan nuevos residentes.",
    text2:
      "El Renacimiento Rural también es una oportunidad laboral. Existen muchas formas en que tu talento puede contribuir al desarrollo y crecimiento de los pueblos.",
    text3:
      "Estamos aquí para ayudarte, ya sea que tengas consultas, estés buscando un nuevo hogar en un pueblo, o quieras formar parte de una comunidad en crecimiento. ¡Contáctanos hoy!",
    name: "Nombre",
    email: "Correo electrónico",
    topic: "Asunto",
    message: "Mensaje",
    send: "Enviar",
    metrics: "Más de 5000 solicitudes recibidas para repoblar el rural",
  },
  cta: {
    text1: "Aldealista es una marca registrada de Grupo Rural Manager SL",
    text2: "Descubre más sobre nosotros en nuestra web",
  },
  errors: {
    errorEmpty:
      "Comprueba tus datos: nombre, email, asunto y mensaje son obligatorios.",
    errorGeneric: "Algo fue mal, inténtalo más tarde",
    errorEmail: "El formato del correo es incorrecto",
    sent: "Formulario enviado correctamente",
  },
  footer: {
    partners: "Nuestro colaboradores",
    org: "Organismos",
    companies: "Empresas",
    foundations: "Fundaciones",
    main: "Tanto si eres una persona joven y activa, madurita e interesante, o vives enfadado con el mundo, tenemos un perfil en tu red social favorita. También puedes contactarnos a nuestro correo electrónico: ",
    copyright: "Todos los derechos reservados",
    available: "Disponible en: ",
  },
  month: {
    dec: "Dic",
    jan: "Ene",
    feb: "Feb",
    mar: "Mar",
    apr: "Abr",
    may: "May",
    jun: "Jun",
    jul: "Jul",
    aug: "Ago",
    sep: "Sep",
    oct: "Oct",
    nov: "Nov",
  },
};
