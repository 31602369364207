export const TRANSLATIONS_EN = {
  menu: {
    about: "Our Story",
    services: "What We Do?",
    ecosystem: "Aldealista Ecosystem",
    media: "Press and Media",
    app: "The App",
    contact: "Contact",
    language: "Language",
  },
  about: {
    bannerTitle: "Aldealista: Making the Invisible Visible",
    bannerSubtitle:
      "Rural Match against depopulation: connecting the present with the future of the countryside",
    bannerFeatures1: "We connect people with rural areas",
    bannerFeaturesSub1:
      "We facilitate the integration of new residents into rural communities, offering opportunities to live, work, and thrive.",
    bannerFeatures2: "We are the movement of Rural Renaissance",
    bannerFeaturesSub2:
      "We revitalize rural areas, promoting sustainable development that attracts people, businesses, and talent towards a prosperous future in the countryside.",
    bannerFeatures3: "Because villages cannot disappear",
    bannerFeaturesSub3:
      "Each village tells a story. We fight to keep that story alive, driving solutions that ensure their survival and growth.",
    mainTitle: "Our Story",
    mainSubtitle: "We were born from a desire, a dream, a mission.",
    header1: "Do something to save these villages",
    text1:
      "These were the words that his grandmother, with 102 years of wisdom, told him before passing away. It was during the pandemic, and Juan Carlos Pérez, then living in Norway, decided to return to his village in Galicia to found Aldealista. It wasn’t just any decision, it was a calling: to save the rural world, to revitalize what seemed condemned to oblivion.",
    text2:
      "Aldealista is not just a platform; it is the engine driving the rebirth of the rural ecosystem. We connect the potentials of each territory, adding value to their resources and attracting people from all over the world. Our goal: for people to choose to stay, work, and live in these places that still have so much to offer.",
    text3:
      "Innovation, creativity, and real impact are at the heart of our proposals. Because we believe that the future is also built from the rural.",
    text4:
      "Today, Aldealista is present in 52 countries, uniting people and communities. We are creating a new rural future, full of opportunities and life.",
    ourTeam: "Our Team",
    ourTeamSubtitle: "Drivers of the Rural Renaissance",
    team1: "Juan Carlos Pérez",
    team1Sub:
      "CEO - Founding Partner, Grupo Rural Manager SL and VPF Scandinavia AS - IT Development",
    team2: "Juan Martín",
    team2Sub:
      "Founding Partner, Grupo Rural Manager SL - Accelerator Controller",
    team3: "María Valcarcel",
    team3Sub: "Brand",
    team4: "Migue Gil",
    team4Sub: "Booking Platform - Subscription",
    team5: "Rocío Pérez",
    team5Sub: "Digital Marketing",
  },
  services: {
    mainTitle: "What We Do?",
    mainSubtitle: "Fighting depopulation: our commitment to the rural world",
    title1: "The Invisible Villages: A Reality We Cannot Ignore",
    text1:
      "In Spain, out of the 8,131 municipalities, 6,827 have less than 5,000 inhabitants. They are invisible villages, but essential.",
    text2:
      "Did you know that 16% of the rural population feeds the other 84% living in cities? This is the paradox of a world that depends on the countryside but leaves it behind.",
    text3:
      "In municipalities with fewer than 5,000 inhabitants, a concerning 44% of the houses are empty or only used as second homes. The countryside is emptying while the city grows.",
    text4:
      "More than 80% of the population lives in less than 20% of the territory. This imbalance invites us to rethink the future of our rural lands.",
    text5: "We can't wait any longer.",
    text6: "It’s time to push for rural development.",
  },
  ecosystem: {
    headerTitle: "Aldealista Ecosystem",
    headerSubtitle: "The Key to the Rural Renaissance",
    text1:
      "Aldealista Ecosystem is more than a platform. It is a comprehensive initiative that connects innovative solutions with the needs of the rural world. Through interconnected services and effective strategies, we are addressing both the challenges and opportunities in these communities.",
    text2:
      "Our goal is not just to attract new residents and tourists to rural areas, but to transform these regions with a holistic approach. We promote economic development, drive job creation, and work to improve infrastructure and essential services to build a vibrant rural future.",
    subHeader: "What Do We Aim to Achieve?",
    subHeaderText1: "Strengthen the rural economy, driving new opportunities.",
    subHeaderText2:
      "Improve the quality of life for those who choose to live and work in these areas.",
    subHeaderText3:
      "Foster sustainable and balanced development that benefits both people and the environment.",
    text3:
      "With these five pillars, Aldealista Ecosystem is the platform that connects, discovers, and grows rural communities, bringing progress where it is most needed.",
  },
  media: {
    mainHeader: "Press",
    subheaderTitle: "Media Impact in 2023: A Significant Footprint",
    subheaderTitleText:
      "In 2023, the estimated media impact of Aldealista Ecosystem reached impressive figures: more than €850,920 in print and digital media, and €106,603 on Radio and Television. These figures underline the scope and relevance of our mission in revitalizing the rural world.",
    subheaderClipping: "Press Clippings",
    subHeaderTextClipping:
      "A selection of news featuring Aldealista, both in national and international media, with a direct link to the original source in its language.",
    subHeaderAwards: "Recognitions and Achievements Driving Our Mission",
    subHeaderAwardsText1:
      "July 2023: Finalists in the App category at the prestigious IV Edition of the European Technology Awards, held at the iconic Ritz Hotel in Paris.",
    subHeaderAwardsText2:
      "November 2023: The Galician Innovation Agency (GAIN) recognized us as part of its selection of innovative success stories.",
    subHeaderAwardsText3:
      "November 22: Winners of the 'Que Vivan los Pueblos' program, an initiative for startups committed to rural development, organized by Yoigo and Unlimited.",
    subHeaderAwardsText4:
      "Best Innovative Company 2023: An award granted by the Confederation of Entrepreneurs of Ourense, highlighting our innovative approach.",
    subHeaderAwardsText5:
      "MadBlue Impact Summit: Winners in the People & Social Impact category, reaffirming our commitment to positive social impact.",
    readMore: "Read more",
  },
  app: {
    headerTitle: "The App",
    headerSubtitle: "Discover Your Village",
    text1:
      "Aldealista is the app for Living Rural Spain, here to empower and become the Resistance of the Rural. Swipe and discover your village!",
    featuresTitle: "How Does It Work?",
    featuresSubtitle: "Master the app in three simple steps:",
    featuresText1: "Select Your Area",
    featuresSubtext1:
      "Tell us which specific part of the geography you want to explore, or select the entire territory if you’re undecided.",
    featuresText2: "Swipe",
    featuresSubtext2:
      "Swipe left or right like in dating apps you claim never to have used; our smart algorithm will learn from your preferences.",
    featuresText3: "Match",
    featuresSubtext3:
      "We believe we’ve found the perfect village for you! Take a look at its profile, discover what it has to offer, and get ready to visit.",
    screenshotTitle: "Psst, take a sneak peek at the app",
    screenshotSubtitle:
      "Here are some screenshots as a sneak peek to help you get familiar with Aldealista.",
    metrics: "Metrics",
    metricsSubtitle: "Our impact in numbers, since January 2023",
    users: "Users",
    matches: "Matches",
    swipes: "Swipes",
  },
  contact: {
    header: "Contact",
    headerSubtitle:
      "We are creating a rural future full of opportunities, and we want you to be part of it. Connect with us and discover how you can live or work in the villages that need your energy!",
    text1:
      "Would you like to live in the countryside? We are helping people like you find their ideal home in rural areas that need new residents.",
    text2:
      "The Rural Renaissance is also a work opportunity. There are many ways your talent can contribute to the development and growth of the villages.",
    text3:
      "We are here to help, whether you have inquiries, are looking for a new home in a village, or want to be part of a growing community. Contact us today!",
    name: "Name",
    email: "Email",
    topic: "Subject",
    message: "Message",
    send: "Send",
    metrics: "More than 5,000 requests received to repopulate rural areas",
  },
  cta: {
    text1: "Aldealista is a registered trademark of Grupo Rural Manager SL",
    text2: "Visit our web and learn more about our services",
  },
  errors: {
    errorEmpty:
      "Check your data: name, email, subject, and message are required.",
    errorGeneric: "Something went wrong, please try again later.",
    errorEmail: "The email format is incorrect.",
    sent: "Form sent successfully.",
  },
  footer: {
    partners: "Our Partners",
    org: "Organizations",
    companies: "Companies",
    foundations: "Foundations",
    main: "Whether you're young and active, mature and interesting, or grumpy at the world, we have a profile on your favorite social network. You can also contact us at our email:",
    copyright: "All rights reserved",
    available: "Available on:",
  },
  month: {
    dec: "Dec",
    jan: "Jan",
    feb: "Feb",
    mar: "Mar",
    apr: "Apr",
    may: "May",
    jun: "Jun",
    jul: "Jul",
    aug: "Aug",
    sep: "Sep",
    oct: "Oct",
    nov: "Nov",
  },
};
