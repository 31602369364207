import React, { createContext, useEffect, useState } from "react";

export const ProfileContext = createContext();

export const ProfileProvider = ({ children }) => {
  const [village, setVillage] = useState([]);
  const modifyVillage = (value) => {
    setVillage(value);
  };

  const [images, setImages] = useState({});
  const modifyImages = (value) => {
    setImages(value);
  };

  const [isImageURL, setIsImageURL] = useState(false);
  const modifyIsImageURL = (value) => {
    setIsImageURL(value);
  };

  const [image, setImage] = useState(false);
  const modifyImage = (value) => {
    setImage(value);
  };

  useEffect(() => {
    console.log("image", image);
  }, [image]);

  useEffect(() => {
    console.log("village context", village);
  }, [village]);

  return (
    <ProfileContext.Provider
      value={{
        village,
        modifyVillage,
        images,
        modifyImages,
        isImageURL,
        modifyIsImageURL,
        image,
        modifyImage,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};
