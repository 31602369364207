import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import axios from "axios";

import "./assets/css/materialdesignicons.min.css";
import "./assets/scss/themes.scss";

import reportWebVitals from "./reportWebVitals";

import Main from "./views/Main";
import VillageProfile from "./views/VillageProfile/VillageProfile";
import { ProfileProvider } from "./context/ContextProfile"; // Import the context provider
import { TabProvider } from "./context/Context"; // Import the context provider

const App = () => {
  const [isVillage, setIsVillage] = useState(false);
  const [villageData, setVillageData] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      const subfolder = location.pathname.split("/")[1]; // Get the first part of the URL

      if (subfolder) {
        axios({
          method: "post",
          url: "https://services.aldealista.com/v1/app/village-info",
          params: {
            code: subfolder,
            isScan: 1,
            userEmail: "gabriel@vpfscandinavia.no",
            userUid: "9cT5kgJ7oyWaA1roSxBUgZgGA1S2",
          },
        })
          .then((res) => {
            console.log("Res data", res.data);
            if (res.data.errorCode === 0) {
              setIsVillage(true);
              setVillageData(res.data);
            }
          })
          .catch((error) => {
            console.error("Error fetching content:", error);
            setIsVillage(false);
            window.history.replaceState(null, "", "/");
          });
      } else {
        setIsVillage(false);
        window.history.replaceState(null, "", "/");
      }
    };

    fetchData();
  }, [location]);

  return isVillage ? <VillageProfile villageData={villageData} /> : <Main />;
};

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <TabProvider>
      <ProfileProvider>
        <Routes>
          <Route path="/*" element={<App />} />
        </Routes>
      </ProfileProvider>
    </TabProvider>
  </BrowserRouter>
);

reportWebVitals();
