import React from "react";
import { Col, Container, Row } from "reactstrap";
import { Link as Link2 } from "react-router-dom";
import Logo from "../assets/images/aldealista-logo.png";
import PlayStore from "../assets/images/playStore.png";
import AppStore from "../assets/images/appStore.png";
import "../languages/i18n.js";
import { useTranslation } from "react-i18next";

export default function Footer() {
  const { t } = useTranslation();

  return (
    <>
      <footer className="bg-footer">
        <Container>
          <Row>
            <div className="col-12">
              <div className="footer-py-60">
                <Row>
                  {/* Col-4: Align content to the left (start) */}
                  <div className="col-lg-4 col-12 mb-lg-0 mb-md-4 pb-lg-0 pb-md-2 d-flex flex-column justify-content-start">
                    <Link2 to="#" className="logo-footer">
                      <img src={Logo} height="75" alt="" />
                    </Link2>
                    <p className="mt-4 mb-0">{t("footer.main")}</p>
                    <div className="pt-3 d-flex align-items-center">
                      <i className="uil uil-envelope me-2 fs-1"></i>
                      <div className="content">
                        <a
                          href="mailto:info@aldealista.com"
                          className="rounded"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <p className="mb-1 text-foot">info@aldealista.com</p>
                        </a>
                      </div>
                    </div>
                  </div>

                  {/* Col-8: Align content to the right (end) */}
                  <div className="col-lg-8 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0 d-flex flex-column justify-content-end align-items-end">
                    <h5
                      className="footer-head text-center"
                      style={{ width: "250px" }}
                    >
                      {t("footer.available")}
                    </h5>
                    <div className="d-flex flex-column">
                      <a
                        href="https://play.google.com/store/apps/details?id=com.aldealista"
                        className="logo-footer pb-3 pt-3"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={PlayStore} width="250" alt="" />
                      </a>
                      <a
                        href="https://apps.apple.com/es/app/aldealista/id1658806105"
                        className="logo-footer pb-3 pt-3"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={AppStore} width="250" alt="" />
                      </a>
                    </div>
                  </div>
                </Row>
              </div>
            </div>
          </Row>
        </Container>

        <div className="footer-py-30 footer-bar">
          <div className="container text-center">
            <Row className="align-items-center justify-content-center">
              <Col sm={8}>
                <div className="text-sm-start">
                  <p className="mb-0">
                    Aldealista © 2020 - {new Date().getFullYear()}{" "}
                    {t("footer.copyright")}
                  </p>
                </div>
              </Col>

              <div className="col-sm-4 mt-4 mt-sm-0">
                <ul className="list-unstyled social-icon text-sm-end foot-social-icon mb-0">
                  <li className="list-inline-item ms-1">
                    <a
                      href="https://instagram.com/aldealista"
                      className="rounded"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i
                        className="uil uil-instagram align-middle"
                        title="instagram"
                      ></i>
                    </a>
                  </li>
                  <li className="list-inline-item ms-1">
                    <a
                      href="https://www.facebook.com/people/Aldealista/100068275251305/"
                      className="rounded"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i
                        className="uil uil-facebook-f align-middle"
                        title="facebook"
                      ></i>
                    </a>
                  </li>
                  <li className="list-inline-item ms-1">
                    <a
                      href="https://x.com/aldealista"
                      className="rounded"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i
                        className="uil uil-twitter align-middle"
                        title="twitter"
                      ></i>
                    </a>
                  </li>
                  <li className="list-inline-item ms-1">
                    <a
                      href="mailto:info@aldealista.com"
                      className="rounded"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i
                        className="uil uil-envelope align-middle"
                        title="email"
                      ></i>
                    </a>
                  </li>
                </ul>
              </div>
            </Row>
          </div>
        </div>
      </footer>
    </>
  );
}
