import React, { createContext, useEffect, useState } from "react";

export const TabContext = createContext();

export const TabProvider = ({ children }) => {
  const [tabIndex, setTabIndex] = useState(0);
  useEffect(() => {
    //console.log("tab", tabIndex);
  }, [tabIndex]);

  const modifyTabIndex = (value) => {
    setTabIndex(value);
  };

  return (
    <TabContext.Provider
      value={{
        tabIndex,
        modifyTabIndex,
      }}
    >
      {children}
    </TabContext.Provider>
  );
};
