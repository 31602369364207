import React, { useContext } from "react";
//import Alsa from "../../assets/img/alsa_logo.png";
import { ProfileContext } from "../../../context/ContextProfile";

function Header() {
  const { village, modifyVillage, modifyImage } = useContext(ProfileContext);

  return (
    <>
      {village.villageData && (
        <div className="row header-qr">
          <div className="col-6 container-image">
            <div className="d-flex justify-content-center h-100">
              <div className="image_outer_container">
                <div className="image_inner_container">
                  <img
                    src={
                      "https://aldealista.com/p/" +
                      village.villageData.profilePicture
                    }
                    onClick={() => {
                      modifyImage(false);
                    }}
                  />
                </div>
              </div>
            </div>
            {/*
            <div className="profile-image-wrapper">
              <img
                src={
                  "https://aldealista.com/p/" +
                  village.villageData.profilePicture
                }
                alt="."
                className="profile-single-image"
              />
            </div>
              */}
          </div>
          <div
            className="col-6 container-text"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <p className="title-qr">{village.villageData.displayName}</p>
            <p className="region">Galicia</p>
            <p className="coordinates">{village.villageData.coordinates}</p>
            {/* 
            <img
                style={{ height: 'auto', width: '100%' }}
                alt="."
                className="img-alsa"
                src={Alsa}
            />
            */}
          </div>
        </div>
      )}
    </>
  );
}

export default Header;
