import { I18nextProvider } from "react-i18next";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { TRANSLATIONS_ES } from "./es.js";
import { TRANSLATIONS_GL } from "./gl.js"; // Change 'gal' to 'gl'
import { TRANSLATIONS_EN } from "./en.js";

// Initialize i18next
i18n.use(initReactI18next).init({
  interpolation: { escapeValue: false }, // React already escapes values, so no need for this
  resources: {
    es: {
      translation: TRANSLATIONS_ES,
    },
    gl: {
      translation: TRANSLATIONS_GL, // Use 'gl' for Galician
    },
    en: {
      translation: TRANSLATIONS_EN,
    },
  },
});

// Check and set the language based on localStorage or fallback to 'es'
const selectedLanguage = localStorage.getItem("language") || "es"; // Default to 'es' if not set

// Make sure the selected language is valid before setting it
if (["es", "gl", "en"].includes(selectedLanguage)) {
  i18n.changeLanguage(selectedLanguage);
} else {
  i18n.changeLanguage("es"); // Fallback to 'es' if the value in localStorage is invalid
}
