import React, { useEffect, useState, useContext } from "react";
import { Row } from "reactstrap";
import { Link } from "react-router-dom";
import { ProfileContext } from "../../context/ContextProfile";
import "../../assets/css/font-awesome.min.css";

import Header from "../../components/qrprofile/Header/Header";
import Footer from "../../components/qrprofile/Footer/Footer";
import Gallery from "../../components/qrprofile/Gallery/Gallery";
import SingleImage from "../../components/qrprofile/SingleImage/SingleImage";
//import Silbina from "../../components/qrprofile/Silbina/Silbina";

import "../../assets/css/qrprofile.css";

export default function VillageProfile({ villageData }) {
  const { village, modifyVillage, images, modifyImages } =
    useContext(ProfileContext);

  const [isMegamatch, setIsMegamatch] = useState(null);

  useEffect(() => {
    console.log("Village data", villageData);
    modifyVillage(villageData);
  }, []);

  return (
    <>
      <div className="top-line"></div>
      <div className="profile-qr">
        <div className="profile-qr-container">
          <div className="profile-container">
            {!images.id ? (
              <>
                {isMegamatch ? (
                  <>
                    {/* <Silbina /> */}
                    <Footer />
                  </>
                ) : (
                  <>
                    <Header />
                    <Gallery />
                    <Footer />
                  </>
                )}
              </>
            ) : (
              <SingleImage />
            )}
            {/*
                <Stories village={village} />
              */}
          </div>
        </div>
      </div>
    </>
  );
}
