import React from "react";
import { Container } from "reactstrap";
import ServicesIMG from "../assets/images/services.png";
import CTA from "../assets/images/bg/cta.png";
import Map from "../assets/images/map.png";
import "../languages/i18n.js";
import { useTranslation } from "react-i18next";

export default function Services() {
  const { t } = useTranslation();

  return (
    <>
      {/* Services  */}
      <section className="section" id="services">
        <div className="container mt-10">
          <div style={{ background: `url(${Map}) center center` }}>
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div className="section-title">
                  <h4 className="title mb-3 main-color">
                    {t("services.mainTitle")}
                  </h4>
                  <h5 className="mb-3">{t("services.mainSubtitle")}</h5>
                  <p className="text-muted">{t("services.text1")}</p>
                  <p className="text-muted">{t("services.text2")}</p>
                  <p className="text-muted">{t("services.text4")}</p>
                </div>
              </div>

              <div className="col-lg-6 col-md-6">
                <div className="position-relative me-lg-5">
                  <img
                    src={ServicesIMG}
                    className="rounded img-fluid mx-auto d-block"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* CTA Start */}
      <section
        className="section"
        style={{
          background: `url(${CTA}) center`,
          backgroundSize: "cover",
          backgroungRepeat: "no-repeat",
        }}
      >
        <div className="bg-overlay"></div>
        <Container>
          <div className="row justify-content-center">
            <div className="col">
              <div className="section-title text-center">
                <h4 className="title text-white mb-3">{t("services.text5")}</h4>
                <h4 className="title text-white mb-3">{t("services.text6")}</h4>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
}
