import React from "react";
import { Col, Row } from "reactstrap";
import Goals from "../assets/images/goals.png";
import Map from "../assets/images/map.png";
import AldealistaLogo from "../assets/images/aldealista-logo.png";
import "../languages/i18n.js";
import { useTranslation } from "react-i18next";

export default function Ecosystem() {
  const { t } = useTranslation();

  return (
    <>
      <section className="section" id="ecosystem">
        <div className="container mt-10">
          <div style={{ background: `url(${Map}) center center` }}>
            <Row className="justify-content-center">
              <Col>
                <div className="section-title text-center mb-4 pb-2">
                  <h4 className="title mb-3 main-color">
                    {t("ecosystem.headerTitle")}
                  </h4>
                  <h5 className="mb-3">{t("ecosystem.headerSubtitle")}</h5>
                  <p className="text-muted mx-auto para-desc mb-0">
                    {t("ecosystem.text1")}
                  </p>
                </div>
              </Col>

              <Col lg={12}>
                <div className="features rounded shadow px-4 py-5 bg-white mt-5">
                  <Row>
                    <div className="col">
                      <div className="d-flex features feature-primary flex-column">
                        <div className="feature-icon text-center d-flex justify-content-center">
                          <img
                            src={AldealistaLogo}
                            className="rounded img-fluid mx-auto d-block"
                            alt=""
                            style={{ width: "45px", marginBottom: "25px" }}
                          />
                        </div>
                        <div className="flex-1 ms-3 px-2">
                          <h5 className="mt-0 text-center">
                            Aldealista Rural Match
                          </h5>
                        </div>
                      </div>
                    </div>

                    <div className="col">
                      <div className="d-flex features feature-primary flex-column">
                        <div className="feature-icon text-center d-flex justify-content-center">
                          <img
                            src={AldealistaLogo}
                            className="rounded img-fluid mx-auto d-block"
                            alt=""
                            style={{ width: "45px", marginBottom: "25px" }}
                          />
                        </div>
                        <div className="flex-1 ms-3 px-2">
                          <h5 className="mt-0 text-center">
                            Aldealista Rural Booking
                          </h5>
                        </div>
                      </div>
                    </div>

                    <div className="col">
                      <div className="d-flex features feature-primary flex-column">
                        <div className="feature-icon text-center d-flex justify-content-center">
                          <img
                            src={AldealistaLogo}
                            className="rounded img-fluid mx-auto d-block"
                            alt=""
                            style={{ width: "45px", marginBottom: "25px" }}
                          />
                        </div>
                        <div className="flex-1 ms-3 px-2">
                          <h5 className="mt-0 text-center">
                            Aldealista Subscription
                          </h5>
                        </div>
                      </div>
                    </div>

                    <div className="col">
                      <div className="d-flex features feature-primary flex-column">
                        <div className="feature-icon text-center d-flex justify-content-center">
                          <img
                            src={AldealistaLogo}
                            className="rounded img-fluid mx-auto d-block"
                            alt=""
                            style={{ width: "45px", marginBottom: "25px" }}
                          />
                        </div>
                        <div className="flex-1 ms-3 px-2">
                          <h5 className="mt-0 text-center">
                            Aldealista Rural Jobs
                          </h5>
                        </div>
                      </div>
                    </div>

                    <div className="col">
                      <div className="d-flex features feature-primary flex-column">
                        <div className="feature-icon text-center d-flex justify-content-center">
                          <img
                            src={AldealistaLogo}
                            className="rounded img-fluid mx-auto d-block"
                            alt=""
                            style={{ width: "45px", marginBottom: "25px" }}
                          />
                        </div>
                        <div className="flex-1 ms-3 px-2 text-center">
                          <h5 className="mt-0">Aldealista Rural State</h5>
                        </div>
                      </div>
                    </div>
                  </Row>
                </div>
              </Col>
            </Row>

            <div className="row align-items-center">
              <div className="col-md-6">
                <div className="me-lg-5">
                  <div className="row justify-content-center">
                    <div className="col-12">
                      <div className="section-title text-center text-md-start mb-4 pb-2">
                        <p className="para-desc text-muted mx-auto mb-3 mt-5">
                          {t("ecosystem.text2")}
                        </p>
                        <p className="para-desc text-muted mx-auto mb-3">
                          {t("ecosystem.text3")}
                        </p>
                        <h4 className="title mb-3 mt-3 pt-5">
                          {t("ecosystem.subHeader")}
                        </h4>
                      </div>
                    </div>
                  </div>

                  <Row>
                    <div className="col-md-12 mt-1">
                      <div className="features feature-primary rounded border-0 d-flex">
                        <div className="feature-icon sm-icon text-center">
                          <i className="rounded-pill">1</i>
                        </div>

                        <div className="content flex-1 ms-3">
                          <p className="title">
                            {t("ecosystem.subHeaderText1")}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12 mt-4 pt-2">
                      <div className="features feature-primary rounded border-0 d-flex">
                        <div className="feature-icon sm-icon text-center">
                          <i className="rounded-pill">2</i>
                        </div>

                        <div className="content flex-1 ms-3">
                          <p className="title">
                            {t("ecosystem.subHeaderText2")}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12 mt-4 pt-2">
                      <div className="features feature-primary rounded border-0 d-flex">
                        <div className="feature-icon sm-icon text-center">
                          <i className="rounded-pill">3</i>
                        </div>

                        <div className="content flex-1 ms-3">
                          <p className="title">
                            {t("ecosystem.subHeaderText3")}
                          </p>
                        </div>
                      </div>
                    </div>
                  </Row>
                </div>
              </div>

              <div className="col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div className="img-fluid-responsive position-relative">
                  <img src={Goals} className="mx-auto d-block" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
