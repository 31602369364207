import React from "react";
import { Col, Container, Row } from "reactstrap";
import "../languages/i18n.js";
import { useTranslation } from "react-i18next";
import SVG1 from "../assets/images/connect.svg";
import SVG2 from "../assets/images/women.svg";
import SVG3 from "../assets/images/villages.svg";

export default function Features() {
  const { t } = useTranslation();

  return (
    <>
      <section className="section bg-light" id="features">
        <Container>
          <div className="row justify-content-center">
            <Col lg={12}>
              <div className="features-absolute rounded shadow px-4 py-5 bg-white">
                {/*<h4 className="title mb-5 text-center">Our Mind Power</h4>*/}

                <Row>
                  <div className="col-lg-4 col-md-6 ">
                    <div className="d-flex features feature-primary flex-column">
                      <div className="feature-icon text-center d-flex justify-content-center">
                        <img
                          src={SVG1}
                          className="rounded img-fluid mx-auto d-block"
                          alt=""
                          style={{ width: "50px" }}
                        />
                      </div>
                      <div className="flex-1 ms-3 px-2">
                        <h5 className="mt-0">{t("about.bannerFeatures1")}</h5>
                        <p className="text-muted mb-0">
                          {t("about.bannerFeaturesSub1")}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-6 mt-4 pt-4 mt-sm-0 pt-sm-0 ">
                    <div className="d-flex features feature-primary flex-column">
                      <div className="feature-icon text-center d-flex justify-content-center">
                        <img
                          src={SVG2}
                          className="rounded img-fluid mx-auto d-block"
                          alt=""
                          style={{ width: "50px" }}
                        />
                      </div>
                      <div className="flex-1 ms-3 px-2">
                        <h5 className="mt-0">{t("about.bannerFeatures2")}</h5>
                        <p className="text-muted mb-0">
                          {t("about.bannerFeaturesSub2")}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-6 mt-4 pt-4 mt-lg-0 pt-lg-0 ">
                    <div className="d-flex features feature-primary flex-column">
                      <div className="feature-icon text-center d-flex justify-content-center">
                        <img
                          src={SVG3}
                          className="rounded img-fluid mx-auto d-block"
                          alt=""
                          style={{ width: "50px" }}
                        />
                      </div>
                      <div className="flex-1 ms-3 px-2">
                        <h5 className="mt-0">{t("about.bannerFeatures3")}</h5>
                        <p className="text-muted mb-0">
                          {t("about.bannerFeaturesSub3")}
                        </p>
                      </div>
                    </div>
                  </div>
                </Row>
              </div>
            </Col>
          </div>
        </Container>
      </section>
    </>
  );
}
