import React, { useEffect, useContext } from "react";
import { ProfileContext } from "../../../context/ContextProfile";
import AldealistaLogo from "../../../assets/images/aldealista-logo.png";

function SliderTop({
  mostVisited,
  recommendations,
  isJob,
  isExperience,
  isHousing,
}) {
  const { village, modifyVillage, modifyImages, modifyImage } =
    useContext(ProfileContext);

  const onImageClick = (image) => {
    var filename = "";

    if (image.isVideo == 1) {
      filename = youtube_parser(image.path[0]);
    } else {
      filename = removeFileExtension(image.path[0]);
    }
    modifyImages(image);

    modifyImage(image);

    window.history.pushState({}, "", `/images/${filename}`);
  };

  function youtube_parser(url) {
    var regExp =
      /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = url.match(regExp);
    return match && match[7].length == 11 ? match[7] : false;
  }

  function removeFileExtension(filename) {
    // Find the last dot in the filename
    const lastDotIndex = filename.lastIndexOf(".");

    // If there's no dot, return the original filename
    if (lastDotIndex === -1) return filename;

    // Return the filename without the extension
    return filename.substring(0, lastDotIndex);
  }

  return (
    <>
      {mostVisited && recommendations && (
        <div
          className="horizontal-slider-container"
          style={{
            margin: 0,
            display: "flex",
            flexDirection: "column", // Keep vertical for header and recommendation
            alignItems: "flex-start",
            padding: "10px",
            maxWidth: "650px",
            width: "100vw",
          }}
        >
          <p style={{ marginBottom: "20px", fontWeight: "bold" }}>
            Lo más visitado
          </p>
          <div
            style={{
              display: "flex",
              flexDirection: "column", // Keep the vertical stacking for header and footer
              padding: "10px",
              overflowX: "auto", // Enable horizontal scrolling
              width: "100%", // Ensure the container is as wide as the screen
              scrollbarWidth: "none", // Hide the scrollbar in Firefox
              msOverflowStyle: "none", // Hide the scrollbar in IE
              "&::-webkit-scrollbar": {
                display: "none", // Hide the scrollbar in Chrome
              },
              maxWidth: "650px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row", // Horizontal scroll direction
                overflowX: "auto", // Enable horizontal scrolling
                whiteSpace: "nowrap", // Prevent line breaks
                paddingBottom: "10px",
                gap: "10px", // Space between images
                maxWidth: "100%", // Ensure the scroll container is only as wide as the screen
              }}
            >
              {mostVisited.map((image, index) => (
                <div
                  key={index}
                  style={{
                    minWidth: "275px", // Set a fixed width for each card
                    minHeight: "175px", // Set a fixed height for each card
                    position: "relative", // Enable absolute positioning for the title
                    textAlign: "center",
                    display: "inline-block",
                    borderRadius: "15px",
                    overflow: "hidden", // Ensure content stays within the card
                  }}
                >
                  <img
                    onClick={() => {
                      onImageClick(image);
                    }}
                    key={image.id}
                    src={`https://aldealista.com/p/${image.path[0]}?w=248&fit=crop&auto=format`}
                    srcSet={
                      image.isVideo ===
                      `https://aldealista.com/p/${image.path[0]}?w=248&fit=crop&auto=format&dpr=2 2x`
                    }
                    alt={image.title}
                    loading="lazy"
                    style={{
                      width: "100%", // Make the image responsive
                      height: "175px", // Make it fill the container
                      objectFit: "cover", // Ensure it covers the area properly
                      borderRadius: "15px",
                    }}
                  />
                  <div
                    style={{
                      position: "absolute",
                      bottom: "0", // Align at the bottom
                      left: "0",
                      right: "0",
                      backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent background
                      color: "white",
                      padding: "10px",
                      fontSize: "14px",
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <span>{image.title}</span>
                    {image.isVideo == 0 ? (
                      image.path.length > 1 && (
                        <span style={{ marginLeft: "10px" }}>
                          <i
                            className="fa fa-copy"
                            aria-label={`info about ${image.title}`}
                          ></i>
                        </span>
                      )
                    ) : (
                      <span style={{ marginLeft: "10px" }}>
                        <i
                          className="fa fa-youtube-play"
                          aria-label={`info about ${image.title}`}
                        ></i>
                      </span>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>

          <p style={{ marginBottom: "20px", fontWeight: "bold" }}>
            Nuestras recomendaciones
          </p>
          <div
            style={{
              display: "flex",
              flexDirection: "column", // Keep the vertical stacking for header and footer
              padding: "10px",
              overflowX: "auto", // Enable horizontal scrolling
              width: "100%", // Ensure the container is as wide as the screen
              scrollbarWidth: "none", // Hide the scrollbar in Firefox
              msOverflowStyle: "none", // Hide the scrollbar in IE
              "&::-webkit-scrollbar": {
                display: "none", // Hide the scrollbar in Chrome
              },
              maxWidth: "650px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row", // Horizontal scroll direction
                overflowX: "auto", // Enable horizontal scrolling
                whiteSpace: "nowrap", // Prevent line breaks
                paddingBottom: "10px",
                gap: "10px", // Space between images
                maxWidth: "100%", // Ensure the scroll container is only as wide as the screen
              }}
            >
              {recommendations.map((image, index) => (
                <div
                  key={index}
                  style={{
                    minWidth: "275px", // Set a fixed width for each card
                    minHeight: "175px", // Set a fixed height for each card
                    position: "relative", // Enable absolute positioning for the title
                    textAlign: "center",
                    display: "inline-block",
                    borderRadius: "15px",
                    overflow: "hidden", // Ensure content stays within the card
                  }}
                >
                  <img
                    onClick={() => {
                      onImageClick(image);
                    }}
                    key={image.id}
                    src={`https://aldealista.com/p/${image.path[0]}?w=248&fit=crop&auto=format`}
                    srcSet={
                      image.isVideo ===
                      `https://aldealista.com/p/${image.path[0]}?w=248&fit=crop&auto=format&dpr=2 2x`
                    }
                    alt={image.title}
                    loading="lazy"
                    style={{
                      width: "100%", // Make the image responsive
                      height: "175px", // Make it fill the container
                      objectFit: "cover", // Ensure it covers the area properly
                      borderRadius: "15px",
                    }}
                  />
                  <div
                    style={{
                      position: "absolute",
                      bottom: "0", // Align at the bottom
                      left: "0",
                      right: "0",
                      backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent background
                      color: "white",
                      padding: "10px",
                      fontSize: "14px",
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <span>{image.title}</span>
                    {image.isVideo == 0 ? (
                      image.path.length > 1 && (
                        <span style={{ marginLeft: "10px" }}>
                          <i
                            className="fa fa-copy"
                            aria-label={`info about ${image.title}`}
                          ></i>
                        </span>
                      )
                    ) : (
                      <span style={{ marginLeft: "10px" }}>
                        <i
                          className="fa fa-youtube-play"
                          aria-label={`info about ${image.title}`}
                        ></i>
                      </span>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default SliderTop;
