import React, { useState, useEffect } from "react";
import {
  Nav,
  NavbarBrand,
  NavbarToggler,
  Collapse,
  NavItem,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { Link } from "react-scroll";
import * as Icon from "react-feather";
import "../../node_modules/swiper/swiper.scss";
import "../../node_modules/swiper/components/navigation/navigation.scss";
import "../../node_modules/swiper/components/pagination/pagination.scss";
import Logodark from "../assets/images/logo-dark.png";
import Logolight from "../assets/images/logo-light.png";
import "../languages/i18n.js";
import { useTranslation } from "react-i18next";

export default function NavBar() {
  const { t, i18n } = useTranslation();

  // State to check if it's mobile or not
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [isOpen, setMenu] = useState(true);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  // Function to toggle the menu on mobile
  const toggleMenu = () => {
    setMenu(!isOpen);
  };

  // Function to toggle the dropdown menu
  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

  // Update the state on window resize
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Scroll effect for the navbar
  useEffect(() => {
    const handleScroll = () => {
      const navbar = document.getElementById("navbar");
      const mybutton = document.getElementById("back-to-top");

      if (
        document.body.scrollTop >= 50 ||
        document.documentElement.scrollTop >= 50
      ) {
        navbar.classList.add("nav-sticky");
      } else {
        navbar.classList.remove("nav-sticky");
      }

      if (
        document.body.scrollTop >= 500 ||
        document.documentElement.scrollTop >= 500
      ) {
        mybutton.classList.add("block");
      } else {
        mybutton.classList.add("none");
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <nav
        id="navbar"
        className="navbar navbar-expand-lg nav-light fixed-top sticky"
      >
        <div className="container">
          <NavbarBrand className="navbar-brand" href="/">
            <span className="logo-light-mode">
              <img src={Logodark} className="l-dark" alt="" />
              <img src={Logolight} className="l-light" alt="" />
            </span>
            <img src={Logolight} className="logo-dark-mode" alt="" />
          </NavbarBrand>
          <NavbarToggler onClick={toggleMenu}>
            <Icon.Menu />
          </NavbarToggler>

          <Collapse
            className={`navbar-collapse ${isOpen === true ? "hidden" : "show"}`}
            id="navbarSupportedContent"
          >
            <Nav
              className="navbar-nav ms-auto mb-2 mb-lg-0"
              id="navbar-navlist"
            >
              {!isMobile ? (
                // Full version for desktop/larger screens
                <>
                  <NavItem>
                    <Link
                      activeClass="active"
                      to="about"
                      spy={true}
                      smooth={true}
                      duration={500}
                      className="nav-link"
                    >
                      {t("menu.about")}
                    </Link>
                  </NavItem>
                  <NavItem>
                    <Link
                      activeClass="active"
                      to="services"
                      spy={true}
                      smooth={true}
                      duration={500}
                      className="nav-link"
                    >
                      {t("menu.services")}
                    </Link>
                  </NavItem>
                  <NavItem>
                    <Link
                      activeClass="active"
                      to="ecosystem"
                      spy={true}
                      smooth={true}
                      duration={500}
                      className="nav-link"
                    >
                      {t("menu.ecosystem")}
                    </Link>
                  </NavItem>
                  <NavItem>
                    <Link
                      activeClass="active"
                      to="app"
                      spy={true}
                      smooth={true}
                      duration={500}
                      className="nav-link"
                    >
                      {t("menu.app")}
                    </Link>
                  </NavItem>
                  <NavItem>
                    <Link
                      activeClass="active"
                      to="media"
                      spy={true}
                      smooth={true}
                      duration={500}
                      className="nav-link"
                    >
                      {t("menu.media")}
                    </Link>
                  </NavItem>
                  <NavItem>
                    <Link
                      activeClass="active"
                      to="contact"
                      spy={true}
                      smooth={true}
                      duration={500}
                      className="nav-link"
                    >
                      {t("menu.contact")}
                    </Link>
                  </NavItem>
                  <Dropdown
                    nav
                    isOpen={dropdownOpen}
                    toggle={toggleDropdown}
                    onMouseEnter={() => setDropdownOpen(true)}
                    onMouseLeave={() => setDropdownOpen(false)}
                  >
                    <DropdownToggle nav caret>
                      {t("menu.language")}
                    </DropdownToggle>
                    <DropdownMenu right>
                      <DropdownItem
                        onClick={() => {
                          i18n.changeLanguage("gl");
                          localStorage.setItem("language", "gl");
                        }}
                      >
                        Galego
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => {
                          i18n.changeLanguage("es");
                          localStorage.setItem("language", "es");
                        }}
                      >
                        Español
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => {
                          i18n.changeLanguage("en");
                          localStorage.setItem("language", "en");
                        }}
                      >
                        English
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </>
              ) : (
                // Compressed version for mobile/smaller screens
                <>
                  <NavItem>
                    <Link
                      activeClass="active"
                      to="about"
                      spy={true}
                      smooth={true}
                      duration={500}
                      className="nav-link"
                    >
                      {t("menu.about")}
                    </Link>
                  </NavItem>
                  <NavItem>
                    <Link
                      activeClass="active"
                      to="services"
                      spy={true}
                      smooth={true}
                      duration={500}
                      className="nav-link"
                    >
                      {t("menu.services")}
                    </Link>
                  </NavItem>
                  <NavItem>
                    <Link
                      activeClass="active"
                      to="ecosystem"
                      spy={true}
                      smooth={true}
                      duration={500}
                      className="nav-link"
                    >
                      {t("menu.ecosystem")}
                    </Link>
                  </NavItem>
                  <NavItem>
                    <Link
                      activeClass="active"
                      to="app"
                      spy={true}
                      smooth={true}
                      duration={500}
                      className="nav-link"
                    >
                      {t("menu.app")}
                    </Link>
                  </NavItem>
                  <NavItem>
                    <Link
                      activeClass="active"
                      to="media"
                      spy={true}
                      smooth={true}
                      duration={500}
                      className="nav-link"
                    >
                      {t("menu.media")}
                    </Link>
                  </NavItem>
                  <NavItem>
                    <Link
                      activeClass="active"
                      to="contact"
                      spy={true}
                      smooth={true}
                      duration={500}
                      className="nav-link"
                    >
                      {t("menu.contact")}
                    </Link>
                  </NavItem>
                  <NavItem>
                    <a
                      className="nav-link"
                      onClick={() => {
                        i18n.changeLanguage("gl");
                        localStorage.setItem("language", "gl");
                        toggleMenu();
                      }}
                    >
                      Galego
                    </a>
                  </NavItem>
                  <NavItem>
                    <a
                      className="nav-link"
                      onClick={() => {
                        i18n.changeLanguage("es");
                        localStorage.setItem("language", "es");
                        toggleMenu();
                      }}
                    >
                      Español
                    </a>
                  </NavItem>
                  <NavItem>
                    <a
                      className="nav-link"
                      onClick={() => {
                        i18n.changeLanguage("en");
                        localStorage.setItem("language", "en");
                        toggleMenu();
                      }}
                    >
                      English
                    </a>
                  </NavItem>
                </>
              )}
            </Nav>
          </Collapse>
        </div>
      </nav>
    </>
  );
}
