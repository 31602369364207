import React, { useEffect } from "react";

import { Link } from "react-scroll";

import * as Icon from "react-feather";

import "../../languages/i18n.js";
import { useLocation } from "react-router-dom";

//Components
import NavBar from "../../components/NavBar";
import Hero from "../../components/Hero";
import Features from "../../components/Features";
import About from "../../components/About";
import Services from "../../components/Services";
import Ecosystem from "../../components/Ecosystem";
import App from "../../components/App";
import Media from "../../components/Media";
import Contact from "../../components/Contact";
import Partners from "../../components/Partners";
import Cta from "../../components/Cta";
import Footer from "../../components/Footer";

import axios from "axios";

export default function Startup() {
  const location = useLocation();

  window.addEventListener("scroll", windowScroll);

  function windowScroll() {
    if (location.pathname.split("/")[1]) {
      return;
    }
    const navbar = document.getElementById("navbar");

    if (
      document.body.scrollTop >= 50 ||
      document.documentElement.scrollTop >= 50
    ) {
      navbar.classList.add("nav-sticky");
    } else {
      navbar.classList.remove("nav-sticky");
    }

    const mybutton = document.getElementById("back-to-top");

    if (
      document.body.scrollTop >= 500 ||
      document.documentElement.scrollTop >= 500
    ) {
      mybutton.classList.add("block");
    } else {
      mybutton.classList.add("none");
    }
  }

  useEffect(() => {
    /*
    toast("We 💚 Pulpeiras " + t("menu.crowd"), {
      style: {
        borderRadius: "10px",
        background: "#333",
        color: "#fff",
        padding: "5",
      },

      duration: 12000,
    });
*/
    /*
    toast(
      <div>
        <a href="https://aldealista.com/pulpeiras" style={{ color: "#fff" }}>
          We 💚 Pulpeiras <br />
          <br /> {t("menu.crowd")}
        </a>
      </div>,
      {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
          padding: "5px", // Make sure this is "5px" if you want it to be a valid CSS value
        },
        duration: 12000,
      }
    );
    */
  }, []);

  useEffect(() => {
    const getIP = async () => {
      const ip = "";
      const res = await axios.get("https://api.ipify.org/?format=json");

      var code = window.location.pathname.replace(/^\/([^\/]*).*$/, "$1");

      axios({
        method: "post",
        url: "https://api.aldealista.com/manager/newVisit",
        params: {
          ip: res.data.ip,
          code: "main-web",
        },
      })
        .then((res) => {})
        .catch((err) => {
          console.log(err);
        });
    };

    getIP();
  }, []);

  return (
    <>
      <div>
        <NavBar />
        <Hero />
        <Features />
        <About />
        <Services />
        <Ecosystem />
        <App />
        <Media />
        <Contact />
        <Partners />
        <Cta />
        <Footer />

        {/* Back to top */}
        <Link
          to="home"
          id="back-to-top"
          className="back-to-top rounded-pill fs-5"
        >
          <Icon.ArrowUp className="fea icon-sm icons align-middle" />
        </Link>
      </div>
    </>
  );
}
