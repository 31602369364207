import React, { useState } from "react";
import { Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";

export default function Contact() {
  const { t } = useTranslation();

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [topic, setTopic] = useState("");
  const [message, setMessage] = useState("");
  const [isSending, setIsSending] = useState(false);

  const sendForm = () => {
    if (isSending) {
      return;
    }

    const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (!reg.test(email) && email !== "") {
      toast.error(t("errors.errorEmail"));
      return;
    }

    if (email === "" || name === "" || message === "" || topic === "") {
      toast.error(t("errors.errorEmpty"));
      return;
    }

    if (message.length < 25) {
      toast.error(t("errors.errorShort"));
      return;
    }

    setIsSending(true);

    console.log(email, name, message, topic);

    (async function () {
      await axios({
        method: "post",
        url: "https://services.aldealista.com/v1/web/messages-web",
        params: {
          email: email,
          name: name,
          message: message,
          topic: topic,
        },
      })
        .then((res) => {
          toast.success(t("errors.sent"));
        })
        .catch((err) => {
          console.log(err);
          setIsSending(false);
          toast.error(t("errors.errorGeneric"));
        });
    })();
  };

  return (
    <>
      {/* Start Contact  */}
      <section className="section bg-light" id="contact">
        <Toaster position="top-center" reverseOrder={false} />

        <Container>
          <Row className="justify-content-center">
            <Col>
              <div className="section-title text-center mb-4 pb-2">
                <h4 className="title mb-3 main-color">{t("contact.header")}</h4>
                <p className="text-muted para-desc mb-0 mx-auto">
                  {t("contact.headerSubtitle")}
                </p>
              </div>
            </Col>
          </Row>

          <Row className="align-items-center">
            <Col lg={8} md={6} className="order-md-2 order-1 mt-4 pt-2">
              <div className="p-4 rounded shadow bg-white">
                <form method="post" name="myForm">
                  <p className="mb-0" id="error-msg"></p>
                  <div id="simple-msg"></div>
                  <Row>
                    <Col md={6}>
                      <div className="mb-4">
                        <input
                          name="name"
                          id="name"
                          type="text"
                          className="form-control"
                          placeholder={t("contact.name")}
                          onChange={(e) => {
                            setName(e.target.value);
                          }}
                        />
                      </div>
                    </Col>

                    <Col md={6}>
                      <div className="mb-4">
                        <input
                          name="email"
                          id="email"
                          type="email"
                          className="form-control"
                          placeholder={t("contact.email")}
                          onChange={(e) => {
                            setEmail(e.target.value);
                          }}
                        />
                      </div>
                    </Col>

                    <div className="col-12">
                      <div className="mb-4">
                        <input
                          name="subject"
                          id="subject"
                          className="form-control"
                          placeholder={t("contact.topic")}
                          onChange={(e) => {
                            setTopic(e.target.value);
                          }}
                        />
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="mb-4">
                        <textarea
                          name="comments"
                          id="comments"
                          rows={4}
                          className="form-control"
                          placeholder={t("contact.message")}
                          onChange={(e) => {
                            setMessage(e.target.value);
                          }}
                        ></textarea>
                      </div>
                    </div>
                  </Row>
                  <Row>
                    <div className="col-12 text-end">
                      <button
                        type="submit"
                        id="submit"
                        name="send"
                        className="btn btn-primary"
                        onClick={(e) => {
                          e.preventDefault();
                          sendForm();
                        }}
                      >
                        {t("contact.send")}
                      </button>
                    </div>
                  </Row>
                </form>
              </div>
            </Col>

            <Col lg={4} md={6} className="col-12 order-md-1 order-2 mt-4 pt-2">
              <div className="me-lg-4">
                <div className="d-flex">
                  <p className="mb-2 text-muted">{t("contact.text1")}</p>
                </div>

                <div className="d-flex mt-4">
                  <p className="mb-2 text-muted">{t("contact.text2")}</p>
                </div>

                <div className="d-flex mt-4">
                  <p className="mb-2 text-muted">{t("contact.text3")}</p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
