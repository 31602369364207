import React, { useState, useRef, useEffect } from "react";
import { Col, Container, Card, Row, CardBody } from "reactstrap";
import MediaIMG from "../assets/images/media.png";
import { useTranslation } from "react-i18next";
import ReactPaginate from "react-paginate";

export default function News() {
  const { t } = useTranslation();

  const BLOG_DATA = [
    {
      title: "A Ruta Galifornia percorre Trives, A Veiga e San Xoán de Río",
      content:
        "Unha aldea con sete persoas censadas que chegou a ter este venres 30 de agosto máis de 350, unidas polo Open de Billarda. Falamos de Castiñeiro, en San Xoán de Río, onde tamén chegou a IX Ruta Galifornia. ",
      date: "31 " + t("month.aug"),
      year: "2024",
      image:
        "https://www.aldealista.com/news/ruta-galifornia-percorreu-trives-rio-veiga.jpg",
      btnClass: "btn",
      url: "https://www.somoscomarca.es/articulo/concellos/ruta-galifornia-percorreu-trives-rio-veiga/20240831163321181562.html",
    },
    {
      title: "El Tinder rural que revive las aldeas",
      content:
        "Juan Carlos Pérez cumplió la promesa que le hizo a su abuela regresando a los orígenes y haciendo algo por el rural gallego. Desde una aldea de Ourense creó Aldealista, una aplicación que conecta a núcleos de la Galicia deshabitada con personas",
      date: "13 " + t("month.aug"),
      year: "2024",
      image:
        "https://www.galiciae.com/asset/thumbnail,1920,1080,center,center/media/galiciae/images/2024/08/12/2024081216310234912.jpg",
      btnClass: "btn",
      url: "https://www.galiciae.com/articulo/galicia/tinder-rural-que-revive-aldeas/20240812163554099295.html",
    },
    {
      title:
        "Rueda y la infanta Elena abordan en Compostela los desafíos y oportunidades del rural gallego",
      content:
        "La Fundación Mapfre, en colaboración con la Xunta de Galicia y la Federación Española de Municipios y Provincias (FEMP) organizó ayer en la Ciudad de la Cultura un evento sobre el desarrollo del rural de la Comunidad que contó con la participación del presidente autonómico, Alfonso Rueda, y la infanta Elena de Borbón.",
      date: "11 " + t("month.jul"),
      year: "2024",
      image:
        "https://diariocompostela.elidealgallego.com/images/showid2/6832092?w=1200&zc=4",
      btnClass: "btn",
      url: "https://diariocompostela.elidealgallego.com/articulo/compostela/rueda-infanta-elena-abordan-compostela-desafios-oportunidades-rural-gallego-4926490",
    },
    {
      title: "Foro ‘Desafíos y Oportunidades de la España rural: Galicia’",
      content:
        "La Infanta Elena de Borbón visitó este jueves Santiago de Compostela en el marco del Foro ‘Desafíos y Oportunidades de la España rural: Galicia’ organizado por Fundación MAPFRE, en colaboración con la Xunta de Galicia y la FEMP.",
      date: "11 " + t("month.jul"),
      year: "2024",
      image: "https://aldealista.com/news/losborbonessonunosladrones.jpg",
      btnClass: "btn",
      url: "https://www.elcorreogallego.es/fotos/galicia/2024/07/11/foro-desafios-oportunidades-espana-rural-105504499.html",
    },
    {
      title:
        "Rueda apuesta por diseñar medidas específicas para el rural en materia de empleo y servicios sociales para garantizar la igualdad de oportunidades",
      content:
        "Pone en valor iniciativas como las casas nido o las casas del mayor, que permiten ofrecer estos servicios donde no funcionan los modelos tradicionales",
      date: "11 " + t("month.jul"),
      year: "2024",
      image: "https://aldealista.com/news/losborbonessonunosladrones.jpg",
      btnClass: "btn",
      url: "https://www.xunta.gal/es/notas-de-prensa/-/nova/003621/rueda-apuesta-por-disenar-medidas-especificas-para-rural-materia-empleo-servicios",
    },
    {
      title:
        "La Xunta cifra en 500 millones los sobrecostes de dar servicios en el rural",
      content:
        "La Fundación Mapfre cuenta con 30 millones en ayudas al rural hasta 2027 -con 20 millones del Fondo Social Europeo Plus-. Galicia recibe cerca medio millón de euros en apoyos para seis proyectos que beneficiarán a unas 200 personas.",
      date: "11 " + t("month.jul"),
      year: "2024",
      image:
        "https://www.laregion.es/asset/thumbnail,1920,1080,center,center/media/laregion/images/2024/07/11/2024071123172862899.jpg",
      btnClass: "btn",
      url: "https://www.laregion.es/articulo/galicia/xunta-cifra-500-millones-sobrecostes-dar-servicios-rural/202407112321331310656.html",
    },
    {
      title:
        "Rueda cifra en 500 millones el ‘sobrecoste’ de prestar servicios en el rural",
      content:
        "En una jornada organizada por la Fundación Mapfre, el presidente de la Xunta admitió que “queda mucho por hacer” en ese ámbito.",
      date: "11 " + t("month.jul"),
      year: "2024",
      image:
        "https://estaticos-cdn.prensaiberica.es/clip/b93ae3ed-b0d8-4e41-8fd1-3930af356ee8_16-9-aspect-ratio_default_0.webp",
      btnClass: "btn",
      url: "https://www.farodevigo.es/sociedad/2024/07/12/rueda-cifra-500-millones-sobrecoste-servicios-rural-105530719.html",
    },
    {
      title:
        "La infanta Elena señala retos en el rural gallego como el envejecimiento y la 'desaparición de oportunidades laborales'",
      content:
        " Participa en Santiago en un foro de la Fundación Mapfre, que apoya a seis iniciativas rurales en Galicia con casi medio millón de euros.",
      date: "11 " + t("month.jul"),
      year: "2024",
      image: "https://aldealista.com/news/losborbonessonunosladrones.jpg",
      btnClass: "btn",
      url: "https://www.europapress.es/galicia/noticia-infanta-elena-senala-retos-rural-gallego-envejecimiento-desaparicion-oportunidades-laborales-20240711115722.html",
    },
    {
      title:
        "La infanta Elena aborda los desafíos del entorno rural gallego en un foro de la Fundación Mapfre.",
      content:
        "La directora de Proyectos Sociales de la entidad destacó el trabajo de los agentes sociales que «tienen la capacidad para llegar adonde otros no llegan».",
      date: "11 " + t("month.jul"),
      year: "2024",
      image:
        "https://img.lavdg.com/sc/LkdBzli4qqQHxAtw0f_2ab6HsIM=/768x/2024/07/11/00121720723982219582372/Foto/GL12P6F1_205111.jpg",
      btnClass: "btn",
      url: "https://www.lavozdegalicia.es/noticia/galicia/2024/07/11/infanta-elena-aborda-desafios-entorno-rural-gallego-foro-fundacion-mapfre/00031720729981830544949.htm",
    },
    {
      title:
        "O Foro sobre a España Rural busca en Galicia proxectos para loitar contra o despoboamento.",
      content:
        "'E se se emprendía nun garaxe de Silicon Valey, por que non vas emprender nun territorio onde hai uns recursos naturais espectaculares? Nós estamos poñendo en marcha un proxecto que se chama Palleiras Valley, e estamos reconstruíndo esas palleiras de pedra, madeira, lousa... Temos casas sen xente e xente sen casa, e claramente aí hai que actuar', engadiu Juan Carlos Pérez, o CEO de Aldealista.",
      date: "11 " + t("month.jul"),
      year: "2024",
      image: "https://aldealista.com/news/losborbonessonunosladrones.jpg",
      btnClass: "btn",
      url: "https://www.g24.gal/-/o-foro-sobre-a-espana-rural-busca-en-galicia-proxectos-para-loitar-contra-o-despoboamento",
    },
    {
      title:
        "Fallece su abuela y este es el homenaje que le hace años después en su pueblo de Orense: sueño cumplido.",
      content:
        "Juan Carlos decide hacer realidad las ilusiones de su abuela centenaria al fallecer en la pandemia y revive la aldea de Castiñeiro castigada por la despoblación",
      date: "20 " + t("month.jun"),
      year: "2024",
      image: "https://cope-cdnmed.cope.es/resources/jpg/3/8/1718793756783.jpg",
      btnClass: "btn",
      url: "https://www.cope.es/emisoras/galicia/ourense-provincia/ourense/noticias/fallece-abuela-este-homenaje-que-hace-anos-despues-pueblo-orense-sueno-cumplido-20240620_3357070",
    },
    {
      title: "Entrevista a Juan Carlos Pérez en Mediodía Cope.",
      content:
        "Juan Carlos Pérez entrevistado en Mediodía Cope, a partir del minuto 11:35.",
      date: "17 " + t("month.jun"),
      year: "2024",
      image:
        "https://www.aldealista.com/news/2024-05-09-galicia-en-la-onda.jpg",
      btnClass: "btn",
      url: "https://www.cope.es/emisoras/galicia/ourense-provincia/ourense/audios/mediodia-cope-ourense-17-06-2024-13-30-20240617_2581465",
    },
    {
      title:
        "Aldealista: la app de la España Rural Viva para evitar la despoblación.",
      content:
        "Descubre cómo Aldealista, la app de España Rural Viva, ayuda a evitar la despoblación en zonas rurales. ¡No te pierdas este reportaje en #VidaTV!",
      date: "04 " + t("month.jun"),
      year: "2024",
      image: "http://i3.ytimg.com/vi/I7XhpnEYjoY/hqdefault.jpg",
      btnClass: "btn",
      url: "https://www.youtube.com/watch?v=I7XhpnEYjoY",
    },
    {
      title: "Expertos globales debaten el futuro del turismo en Galicia.",
      content:
        "Juan Carlos Pérez, socio fundador de Grupo Rural Manager S.L. (España), expuso sobre “Aldealista: una aceleradora social de pueblos y espacios rurales”.",
      date: "30 " + t("month.may"),
      year: "2024",
      image:
        "https://trafficamerican.com/wp-content/uploads/2024/05/Panel-1.jpg",
      btnClass: "btn",
      url: "https://trafficamerican.com/n212/",
    },
    {
      title:
        "ASICOTUR organiza por primera vez el I Simposio de Cooperación Turística Internacional en los días 29 y 30 de mayo en Sanxenxo.",
      content:
        "La última presentación de este panel estuvo a cargo de Juan Carlos Pérez, socio fundador de Grupo Rural Manager S.L. (España) quien expuso la ponencia “ Aldealista: una aceleradora social de pueblos y espacios rurales”.",
      date: "29 " + t("month.may"),
      year: "2024",
      image:
        "https://www.caribbeannewsdigital.com/sites/default/files/2024-05/WhatsApp%20Image%202024-05-29%20at%202.30.30%20PM.jpeg",
      btnClass: "btn",
      url: "https://www.caribbeannewsdigital.com/es/turismo/sesiono-la-primera-jornada-del-i-simposio-de-cooperacion-turistica-internacional",
    },
    {
      title: "La 'app de citas' que te hará volver al pueblo",
      content:
        "El proyecto Aldealista, lanzado hace poco más de un año por el empresario gallego Juan Carlos Pérez, nace con el propósito de fijar población en las áreas rurales.",
      date: "21 " + t("month.may"),
      year: "2024",
      image:
        "https://s2.abcstatics.com/abc/www/multimedia/antropia/2024/05/20/proyecto-aldealista-kWBG-U220201827260yeD-1200x840@RC.jpg",
      btnClass: "btn",
      url: "https://www.abc.es/antropia/app-citas-hara-volver-pueblo-20240520121622-nt.html",
    },
    {
      title: "Aldealista: hacer «match» contra la despoblación",
      content:
        "Una aplicación nacida en una aldea de Ourense hace poco más de un año ha sido premiada como mejor «start-up» de España en el MadBlue Summit 2024.",
      date: "12 " + t("month.may"),
      year: "2024",
      image:
        "https://cflvdg.avoz.es/sc/-4saFWJlmAskNUmMdKhkpvjP2ZA=/768x/2024/05/10/00121715346602092868455/Foto/j_20240509_160031000.jpg",
      btnClass: "btn",
      url: "https://www.lavozdegalicia.es/noticia/mercados/2024/05/12/aldealista-match-contra-despoblacion/0003_202405SM12P9991.htm",
    },
    {
      title: "Juan Carlos Pérez Rodríguez en Galicia en la Onda",
      content:
        "Entrevista a Juan Carlos Pérez Rodríguez, socio fundador de Aldealista, no programa Galicia en la Onda de Ondacero.",
      date: "09 " + t("month.may"),
      year: "2024",
      image:
        "https://www.aldealista.com/news/2024-05-09-galicia-en-la-onda.jpg",
      btnClass: "btn",
      url: "https://www.aldealista.com/news/2024-05-09-galicia-en-la-onda.mp3",
    },
    {
      title:
        "¿Podemos usar soluciones de países del Sur Global para atajar la despoblación en España?",
      content:
        "Aldealista busca otorgar mayor visibilidad a los pueblos y a sus comercios locales a través de una app. Es clave fomentar el orgullo de ser o vivir en un pueblo.",
      date: "08 " + t("month.may"),
      year: "2024",
      image:
        "https://imagenes.elpais.com/resizer/v2/N46OIZOOPZB5RA6PNG6G3A3Q6I.jpg?auth=4d6c54edbf65d1a6fb572d0ad8d77e02e0d99d8cee3ae82679ec9ca721cb7cf8&width=828",
      btnClass: "btn",
      url: "https://elpais.com/planeta-futuro/2024-05-08/podemos-usar-soluciones-de-paises-del-sur-global-para-atajar-la-despoblacion-en-espana.html#",
    },
    {
      title:
        "Mutur Beltz y Aldealista: dos startups que luchan contra el éxodo rural",
      content:
        "En el tercer programa de ‘¡Que Vivan Los Pueblos!’, Paula Muñoz y Gabriela Campbell, de Ac2ality, viajan junto al cómico Pablo Meixe a dos pueblos de Vizcaya y Ourense.",
      date: "08 " + t("month.may"),
      year: "2024",
      image:
        "https://www.unltdspain.org/wp-content/uploads/2023/06/que-vivan-los-pueblos-unlimited-spain-yoigo.png",
      btnClass: "btn",
      url: "https://www.antena3.com/brand/quevivanlospueblos/",
    },
    {
      title: "Muturbeltz & Aldealista",
      content:
        "Conocemos Aldealista, app que ayuda a conectar el medio rural con la sociedad y promueve la repoblación y Mutur Beltz, que promueve la oveja carranzana y fomenta el auzolan.",
      date: "07 " + t("month.may"),
      year: "2024",
      image:
        "https://imagenes.atresplayer.com/atp/clipping/cmsimages02/2024/05/06/2FF45796-E0E4-4C4B-86B6-D689312BDD16//720x540.jpg",
      btnClass: "btn",
      url: "https://www.atresplayer.com/programas/que-vivan-los-pueblos/temporada-1/muturbeltz-aldealista_6626359374e9b0e4421b379d/",
    },
    {
      title: "La ourensana Aldealista, elegida la mejor startup de España",
      content:
        "Aldealista es un caso de éxito basado en Tinder. El Colegio Oficial de Arquitectos de Madrid (COAM) se transformó en el epicentro de la innovación y el impacto social con la celebración del MadBlue Impact Summit 2024.",
      date: "02 " + t("month.may"),
      year: "2024",
      image:
        "https://www.laregion.es/asset/thumbnail,1920,1080,center,center/media/laregion/images/2024/05/01/2024050123265465557.jpg",
      btnClass: "btn",
      url: "https://www.laregion.es/articulo/trives/ourensana-aldealista-elegida-mejor-startup-espana/202405020327391291376.html",
    },
    {
      title: "La aldea de O Castiñeiro luce un mural de Mon Devane",
      content:
        "El muralista cedía esta obra a Juan Carlos Pérez de Aldealista, que trabaja en un mecenazgo para dar visión a las pulpeiras, una de las profesiones más características en Galicia, como explican ambos.",
      date: "27 " + t("month.apr"),
      year: "2024",
      image:
        "https://www.laregion.es/asset/thumbnail,1920,1080,center,center/media/laregion/images/2024/04/26/2024042622515370724.jpg",
      btnClass: "btn",
      url: "https://www.laregion.es/articulo/trives/aldea-castineiro-luce-mural-mon-devane/202404270500001290066.html",
    },
    {
      title:
        "Madblue Impact Summit se fija en el potencial de los océanos y en implantar una economía de impacto",
      content:
        "Datafruit se ha alzado como la ganadora de Food Security & Water Access; Aldealista, en la categoría People & Social Impact: Urban Air Purifier (UAP) en la de Smart Cities; Calpech en la de Low Carbon Transition y Seaweed Enterprises en la de Tech & New Economy.",
      date: "26 " + t("month.apr"),
      year: "2024",
      image:
        "https://okdiario.com/img/2024/04/26/madblue-impact-summit-635x358.jpg",
      btnClass: "btn",
      url: "https://okdiario.com/okgreen/madblue-impact-summit-fija-potencial-oceanos-implantar-economia-impacto-12745066",
    },
    {
      title:
        "'Si no hay Pulpeira no hay Feira': la falta de relevo amenaza esta profesión de Galicia",
      content:
        "La plataforma Aldealista se ha propuesto dar visibilidad a una de las profesiones más características de la comunidad, especialmente en zonas del rural",
      date: "12 " + t("month.apr"),
      year: "2024",
      image:
        "https://media.quincemil.com/imagenes/2024/04/10175427/shutterstock_1598423245_le_photos_v2_custom-1706x960.jpg",
      btnClass: "btn",
      url: "https://www.elespanol.com/quincemil/articulos/economia/si-no-hay-pulpeira-no-hay-feira-la-falta-de-relevo-amenaza-esta-profesion-de-galicia",
    },
    {
      title:
        "El edificio polivalente de Manuel Rivero acoge el día 5 el tercer Work Cocido Lalín",
      content:
        "La programación arranca en el auditorio a las 10.30 horas, con cinco ponencias de emprendedores de cinco sectores distintos, entre ellos Juan Carlos Pérez, socio fundador de Aldealista",
      date: "31 " + t("month.mar"),
      year: "2024",
      image: "https://www.aldealista.com/news/work-cocido-02.jpg",
      btnClass: "btn",
      url: "https://www.farodevigo.es/deza-tabeiros-montes/2024/03/31/edificio-polivalente-manuel-rivero-acoge-100456555.html",
    },
    {
      title:
        "El encuentro Work Cocido Lalín incluirá el día 5 de abril cinco ponencias",
      content:
        "Participará Juan Carlos Pérez, socio fundador de Aldealista, que se desarrolla su actividad profesional como agente de cambio rural, enfocado en el impacto positivo de la lucha contra la despoblación.",
      date: "31 " + t("month.mar"),
      year: "2024",
      image: "https://www.aldealista.com/news/work-cocido-01.jpg",
      btnClass: "btn",
      url: "https://www.lavozdegalicia.es/noticia/deza/lalin/2024/03/31/encuentro-work-cocido-lalin-incluira-dia-5-abril-cinco-ponencias/0003_202403D31C2993.htm",
    },
    {
      title:
        "Campaña de crowdfunding de Aldealista na Revista da CRTVG para reivindicar as polbeiras",
      content:
        "Reportaxe na CRTVG sobre a campaña de crowdfunding de Aldealista para visibilizar as polbeiras, no programa A Revista, a partir do 30:40.",
      date: "10 " + t("month.mar"),
      year: "2024",
      image:
        "https://www.crtvg.es/files/web/b55a2b9b-afaa-4933-a9f2-a56feca6be8d.png",
      btnClass: "btn",
      url: "https://agalega.gal/videos/129382-clip-revista/",
    },
    {
      title: "Unha campaña para reivindicar as polbeiras",
      content:
        "Reportaxe na RTVE sobre a campaña de crowdfunding de Aldealista para visibilizar as polbeiras, a partir do 18:23.",
      date: "10 " + t("month.mar"),
      year: "2024",
      image:
        "https://upload.wikimedia.org/wikipedia/commons/e/ee/Logo_RTVE.svg",
      btnClass: "btn",
      url: "https://www.rtve.es/play/videos/telexornal-galicia/telexornal-galicia-2-11-03-2024/16011219/",
    },
    {
      title:
        "Objetivo: crowfunding con venta de imanes y calcetines, para crear el gran archivo mundial en red de las pulpeiras",
      content:
        "Aldealista impulsa esta iniciativa, para recopilar fotografías e historias de cada una de estas mujeres 'guardianas de un oficio en riesgo por falta de relevo y desaparición de ferias'.",
      date: "10 " + t("month.mar"),
      year: "2024",
      image:
        "https://estaticos-cdn.prensaiberica.es/clip/b461a5dd-48e3-452c-8fd3-7d25825008ce_source-aspect-ratio_default_0.webp",
      btnClass: "btn",
      url: "https://www.farodevigo.es/ourense/2024/03/09/objetivo-crowfunding-venta-imanes-calcetines-99225149.html",
    },
    {
      title: "Aldealista, presente en el MadBlue de Madrid",
      content:
        "La plataforma digital Aldealista, promovida por Juan Carlos Pérez de Castiñeiro en San Xoán de Río, ha sido seleccionada para participar en la jornada internacional MadBlue Impact Summit 2024. Este evento, considerado como uno de los más relevantes en innovación, cultura y ciencia hacia el desarrollo sostenible, tiene lugar cada primavera en Madrid coincidiendo con la Semana de la Tierra.",
      date: "06 " + t("month.mar"),
      year: "2024",
      image:
        "https://www.laregion.es/asset/thumbnail,1920,1080,center,center/media/laregion/images/2023/11/14/2023111422594724517.jpg",
      btnClass: "btn",
      url: "https://www.laregion.es/articulo/ourense/aldealista-presente-madblue-madrid/202403060845221277927.html",
    },
    {
      title:
        "Aldealista será la única startup gallega en MadBlue Impact Summit",
      content:
        "Este evento tendrá lugar del 24 al 25 de abril en el COAM de Madrid.",
      date: "05 " + t("month.mar"),
      year: "2024",
      image:
        "https://img-static.ivoox.com/index.php?w=141&h=141&url=https://static-1.ivoox.com/audios/b/2/5/5/b255f53009142fa78c36c4f1180cf793_XXL.jpg",
      btnClass: "btn",
      url: "https://www.ivoox.com/aldealista-sera-unica-startup-gallega-madblue-audios-mp3_rf_125346680_1.html",
    },
    {
      title:
        "Aldealista será la única startup gallega en MadBlue Impact Summit",
      content:
        "Aldelista sigue atravesando fronteras y en esta ocasión viajará a Madrid de la mano de MadBlue Impact Summit. En esta cuarta edición, Aldealista será  la única startup rural gallega.",
      date: "05 " + t("month.mar"),
      year: "2024",
      image:
        "https://www.somoscomarca.es/asset/thumbnail,992,558,center,center/media/somoscomarca/images/2024/03/05/2024030516570986671.jpg",
      btnClass: "btn",
      url: "https://www.somoscomarca.es/articulo/terras-trives/aldealista-sera-unica-startups-gallega-madblue-impact-summit/20240305115451169541.html",
    },
    {
      title: "Una aplicación crea 'historias de amor' entre personas y aldeas.",
      content:
        "Juan Carlos Pérez ha convertido su aldea de Castiñeiro en el epicentro de Aldealista, una aplicación móvil que han bautizado como “el Tinder de los pueblos”, pero cuya misión principal es luchar contra la despoblación visibilizando todo el potencial y la calidad de vida del rural.",
      date: "28 " + t("month.jan"),
      year: "2024",
      image: "https://aldealista.com/news/laregion-innovacion-2024-01-28.jpg",
      btnClass: "btn",
      url: "https://aldealista.com/news/laregion-innovacion-2024-01-28.pdf",
    },
    {
      title:
        "O Concello de San Xoán de Río aposta por crear a súa Comunidade Enerxética",
      content:
        "O obxectivo desta cooperativa é “a democratización da enerxía con fontes de enerxías renovables para que San Xoán de Río sexa un municipio máis sostible e que beneficiará a todos os socios cooperativistas que sexan partícipes desta iniciativa proposta por CEL Managementy Grupo Rural Manager”",
      date: "15 " + t("month.jan"),
      year: "2024",
      image: "https://osil.info/wp-content/uploads/2024/01/rio-1.jpg",
      btnClass: "btn",
      url: "https://osil.info/o-concello-de-san-xoan-de-rio-aposta-por-crear-a-sua-comunidade-enerxetica/",
    },
    {
      title: "La Diputación concede 123.000 euros a Quiroga",
      content:
        "Estos fondos se destinarán, por una parte, a la adquisición de un tractor para arreglar y mantener pistas, y por otra, a implantar en el municipio la plataforma digital Aldealista, con lo que se espera potenciar la promoción turística.",
      date: "29 " + t("month.dec"),
      year: "2023",
      image:
        "https://cflvdg.avoz.es/sc/hnh43O39yok5VdceMQ1l4xAGtdE=/768x/2023/12/28/00121703783941979288451/Foto/M_20231228_174205000.jpg",
      btnClass: "btn",
      url: "https://www.lavozdegalicia.es/amp/noticia/lemos/quiroga/2023/12/29/diputacion-concede-123000-euros-quiroga-adquirir-tractor/0003_202312M29C2997.htm",
    },
    {
      title: "El ‘Tinder de los pueblos’ que enamora al rural gallego",
      content:
        "Juan Carlos Pérez es hijo de emigrantes y fundador de ‘Aldealista’, una app que suma este año 250.000 visitas de 51 países y que, siguiendo el modelo de la tradicional aplicación de citas, se ha convertido hoy en una de las principales herramientas de innovación y vida en nuestro rural.",
      date: "16 " + t("month.dec"),
      year: "2023",
      image:
        "https://fotografias.larazon.es/clipping/cmsimages01/2023/12/15/E2045536-04D2-466F-9C3C-D333C7B7A643/san-xoan-rio-hoy-mejor-pueblo-tecnologico-galicia_98.jpg?crop=1600,900,x0,y151&width=1900&height=1069&optimize=low&format=webply",
      btnClass: "btn",
      url: "https://www.larazon.es/galicia/tinder-pueblos-que-enamora-rural-gallego_20231216657c9ba7d7b0c3000190d9bd.html",
    },
    {
      title:
        "A Xunta destaca o potencial innovador do rural para atraer nómades dixitais e impulsar a economía local",
      content:
        "A conselleira de Promoción do Emprego e Igualdade, Elena Rivo, e o director de Turismo de Galicia, Xosé Merelles, visitaron en San Xoán de Río o proxecto Aldealista, que pon en contacto usuarios con áreas rurais adaptadas aos seus perfís.",
      date: "10 " + t("month.dec"),
      year: "2023",
      image: "https://cope-cdnmed.cope.es/resources/jpg/3/1/1702132870413.jpg",
      btnClass: "btn",
      url: "https://www.cope.es/emisoras/galicia/amp/noticias/xunta-destaca-potencial-innovador-rural-para-atraer-nomades-dixitais-impulsar-economia-local-20231209_3041558",
    },
    {
      title:
        "La Xunta apunta como vía de desarrollo del rural la captación de nómadas digitales",
      content:
        "La Xunta señala la captación de nómadas digitales como una vía para “impulsar la economía local” de áreas rurales. La Conselleira de Promoción do Emprego e Igualdade, Elena Rivo, y el director de Turismo de Galicia, Xosé Merelles, visitaron ayer la aldea de Castiñeiro, en el concello ourensano de San Xoán de Río, y aplaudieron el proyecto Aldealista “ejemplo de innovación” y guía para el futuro de otros entornos similares.",
      date: "10 " + t("month.dec"),
      year: "2023",
      image: "https://www.xunta.gal/image/image_gallery?img_id=2028963",
      btnClass: "btn",
      url: "https://www.farodevigo.es/galicia/2023/12/10/xunta-apunta-via-desarrollo-rural-95648483.html",
    },
    {
      title: "La Xunta impulsará en el rural ourensano espacios “co-living”",
      content:
        "La aldea de Castiñeiro en San Xoán do Río, germen del proyecto Aldealista fundado por Juan Carlos Pérez, recibieron ayer la visita de la conselleira de Promoción do Emprego e Igualdade, Elena Rivo, así como del director de Turismo de Galicia para poner en valor esta iniciativa reconocida como ejemplo de innovación por parte de la Xunta de Galicia.",
      date: "10 " + t("month.dec"),
      year: "2023",
      image:
        "https://www.laregion.es/asset/thumbnail,1920,1080,center,center/media/laregion/images/2023/12/09/2023120922324895960.jpg",
      btnClass: "btn",
      url: "https://www.laregion.es/articulo/trives/xunta-impulsara-rural-ourensano-espacios-co-living/202312100700001259801.html",
    },
    {
      title:
        "A Xunta destaca o potencial para o rural do proxecto Aldealista de San Xoán de Río",
      content:
        "A conselleira de Promoción do Emprego e Igualdade, Elena Rivo, e o director de Turismo de Galicia, Xosé Merelles, visitaron este sábado a aldea do Castiñeiro, en San Xoán de Río, onde coñeceron o proxecto Aldealista da man do seu fundador, Juan Carlos Pérez. Trátase dunha iniciativa que pon en contacto usuarios con áreas rurais adaptadas aos seus perfís e que é un exemplo de innovación recoñecido pola Xunta. Na visita tamén estivo presente o alcalde do municipio ourensán, José Miguel Pérez.",
      date: "10 " + t("month.dec"),
      year: "2023",
      image:
        "https://cflvdg.avoz.es/sc/-6srpBfcvl3pnMDfg7hUqYr5U7w=/768x/2023/12/09/00121702139762093295210/Foto/234423324.jpg",
      btnClass: "btn",
      url: "https://www.lavozdegalicia.es/amp/noticia/ourense/2023/12/09/span-langgl-proxecto-aldealista-san-xoan-riopon-contacto-usuarios-areas-rurais-adaptadas-aos-seus-perfisspan/00031702139559627412312.htm",
    },
    {
      title:
        "Aldealista, unha aplicación móbil que está a encher de vida as aldeas",
      content:
        "Un veciño da aldea de Castiñeiro puxo en marcha esta iniciativa e desde a pandemia xa se censaron sete novos habitantes.",
      date: "09 " + t("month.dec"),
      year: "2023",
      image: "https://www.xunta.gal/image/image_gallery?img_id=2028963",
      btnClass: "btn",
      url: "https://www.g24.gal/gl/w/aldealista-unha-aplicacion-mobil-que-esta-a-encher-de-vida-as-aldeas",
    },
    {
      title:
        "Elena Rivo e Xosé Merelles, visitaron en San Xoán de Río o proxecto Aldealista",
      content:
        " A Xunta está a despregar no territorio a Rede de polos de emprendemento para favorecer a xeración de emprego e o crecemento económico local e traballa na creación de espazos co-living no rural para atraer nómades dixitais.",
      date: "09 " + t("month.dec"),
      year: "2023",
      image:
        "https://www.somoscomarca.es/asset/thumbnail,992,558,center,center/media/somoscomarca/images/2023/12/09/2023120919592310518.jpg",
      btnClass: "btn",
      url: "https://www.somoscomarca.es/articulo/terras-trives/elena-rivo-xose-merelles-visitaron-san-xoan-rio-proxecto-aldealista/20231209200333163929.html",
    },
    {
      title:
        "La Xunta destaca el potencial innovador del rural para atraer nómadas digitales e impulsar la economía local",
      content:
        "La conselleira de Promoción do Emprego e Igualdade, Elena Rivo, y el director de Turismo de Galicia, Xosé Merelles, visitaron en San Xoán de Río el proyecto Aldealista, que pone en contacto usuarios con áreas rurales adaptadas a sus perfiles.",
      date: "09 " + t("month.dec"),
      year: "2023",
      image: "https://www.xunta.gal/image/image_gallery?img_id=2028963",
      btnClass: "btn",
      url: "https://www.xunta.gal/notas-de-prensa/-/nova/85871/xunta-destaca-potencial-innovador-del-rural-para-atraer-nomadas-digitales-impulsar?langId=es_ES",
    },
    {
      title:
        "La Xunta aboga por el emprendimiento para dinamizar el rural gallego",
      content:
        "Así se desprende de la visita de la conselleira de Promoción do Emprego e Igualdade, Elena Rivo, y el director de Turismo de Galicia, Xosé Merelles, a la aldea ourensana de O Castiñeiro. Allí conocieron de primera mano el proyecto Aldealista, nacido en esta localidad de la mano de Juan Carlos Pérez, una aplicación que pone en contacto particulares con localidades con menos de 5.000 habitantes.",
      date: "09 " + t("month.dec"),
      year: "2023",
      image: "https://www.xunta.gal/image/image_gallery?img_id=2028969",
      btnClass: "btn",
      url: "https://www.elespanol.com/treintayseis/articulos/economia/la-xunta-aboga-por-el-emprendimiento-para-dinamizar-el-rural-gallego",
    },
    {
      title:
        "La Xunta pone en valor 'Aldealista', una app móvil que une usuarios con zonas rurales adaptadas a sus perfiles",
      content:
        "La conselleira de Promoción do Emprego e Igualdade, Elena Rivo, y el director de Turismo de Galicia, Xosé Merelles, han visitado este sábado la aldea de O Castiñeiro, donde han conocido de la mano de su fundador, Juan Carlos Pérez, el proyecto Aldealista, nacido en esta localidad, como ejemplo de innovación reconocido por la Xunta.",
      date: "09 " + t("month.dec"),
      year: "2023",
      image: "https://www.xunta.gal/image/image_gallery?img_id=2028975",
      btnClass: "btn",
      url: "https://www.europapress.es/galicia/agro-00246/noticia-xunta-pone-valor-aldealista-app-movil-une-usuarios-zonas-rurales-adaptadas-perfiles-20231209174456.html",
    },
    {
      title:
        "Proxecto 'Aldealista': unha app móbil que une usuarios con zonas rurais adaptadas aos seus perfís",
      content:
        "A conselleira de Promoción do Emprego e Igualdade, Elena Rivo, e o director de Turismo de Galicia, Xosé Merelles, visitaron este sábado a aldea do Castiñeiro, onde coñeceron da man do seu fundador, Juan Carlos Pérez, o proxecto Aldealista, nacido nesta localidade, como exemplo de innovación recoñecido pola Xunta.",
      date: "09 " + t("month.dec"),
      year: "2023",
      image:
        "https://www.galiciaconfidencial.com/imgpipe/2023_12_9_124825s740x.jpg",
      btnClass: "btn",
      url: "https://www.galiciaconfidencial.com/noticia/248576-proxecto-aldealista-app-mobil-une-usuarios-zonas-rurais-adaptadas-aos-perfis",
    },
    {
      title:
        "Arranca la 1ª edición de ¡Que Vivan Los Pueblos!, un programa de aceleración de startups impulsado por Yoigo para transformar la economía de la España Rural.",
      content:
        "En concreto, las startups que serán aceleradas en esta primera edición de ¡Que Vivan Los Pueblos! son: Aldealista: una aplicación móvil que busca aportar una mayor visibilidad a los pueblos y a sus comercios locales.",
      date: "06 " + t("month.dec"),
      year: "2023",
      image:
        "https://elreferente.es/wp-content/uploads/2023/12/Que-Vivan-Los-Pueblos.jpg",
      btnClass: "btn",
      url: "https://elreferente.es/actualidad/arranca-1a-edicion-que-vivan-los-pueblos-programa-aceleracion-startups-impulsado-yoigo-transformar-economia-espana-rural/",
    },
    {
      title: "Del corazón de una aldea al resto del mundo",
      content:
        "El portal Aldealista, que da a conocer los pueblos del rural gallego y contribuye a dinamizar su contorno, viene de recibir una salva de reconocimientos por su innovación y de presentarse en un campus de Google",
      date: "04 " + t("month.dec"),
      year: "2023",
      image:
        "https://s3.abcstatics.com/abc/www/multimedia/espana/2023/12/03/aldea1-ROl9F7KVbKZwRaWiJlm08fJ-758x531@abc.jpg",
      btnClass: "btn",
      url: "https://www.abc.es/espana/galicia/corazon-aldea-resto-mundo-20231204181210-nt.html",
    },
    {
      title:
        "La CEO reconoce tres proyectos empresariales vinculados a la comarca ",
      content:
        "En el apartado de responsabilidad social, el premio lo recibió Grupo Rural Manager quien gestiona e la plataforma digital Aldealista, “el Tinder de los pueblos”. Es un proyecto que nació en San Xoán de Río y que integra la inteligencia artificial y la selección visual de los recursos turísticos y patrimoniales en un nuevo modelo de negocio disruptivo.",
      date: "03 " + t("month.dec"),
      year: "2023",
      image:
        "https://www.somoscomarca.es/asset/thumbnail,768,432,center,center/media/somoscomarca/images/2023/12/02/2023120214471666569.jpg",
      btnClass: "btn",
      url: "https://www.somoscomarca.es/articulo/empresa/ceo-reconoce-proyectos-empresariales-vinculados-comarca/20231203093906163482.amp.html",
    },
    {
      title:
        "Juan Carlos Pérez de Grupo Rural Manager S.L., con sede en Castiñeiro (Río), na entrega de premios",
      content:
        "Intervención de Juan Carlos Pérez tras a distinción de categoría de empresa innovadora na gala anual de empresas ourensás.",
      date: "02 " + t("month.dec"),
      year: "2023",
      image: "https://aldealista.com/news/gala-2023.jpg",
      btnClass: "btn",
      url: "https://www.youtube.com/watch?v=yI28TH6fs2k",
    },
    {
      title:
        "La patronal galardona a seis empresas y mide la huella de carbono del acto para plantar árboles",
      content:
        "Seis compañías de Ourense, entre ellas Grupo Rural Manager, distinguidas por su aportación al comercio, la sostenibilidad, las exportaciones, la innovación, la empresa familiar así como la responsabilidad social ",
      date: "02 " + t("month.dec"),
      year: "2023",
      image:
        "https://estaticos-cdn.prensaiberica.es/clip/46e7a6e5-5172-4c9a-8465-519b640425cf_21-9-aspect-ratio_default_0.jpg",
      btnClass: "btn",
      url: "https://www.farodevigo.es/ourense/2023/12/02/patronal-galardona-seis-empresas-mide-95364524.html",
    },
    {
      title:
        "Grupo Rural Manager galardonado en la gala anual de empresas ourensanas.",
      content:
        "En la categoría de empresa innovadora, la CEO se fijó en el Grupo Rural Manager, que es el responsable de la plataforma digital innovadora Aldealista, conocida como el Tinder de los pueblos. Es un proyecto que nació en San Xoán de Río y que integra la inteligencia artificial y la selección visual de los recursos turísticos y patrimoniales en un nuevo modelo de negocio disruptivo.",
      date: "02 " + t("month.dec"),
      year: "2023",
      image:
        "https://cflvdg.avoz.es/sc/G2FF4iSR7WK6ESaQZYQmWcpngWI=/768x/2023/12/01/00121701453901613991837/Foto/OD2C1F1_19353.jpg",
      btnClass: "btn",
      url: "https://www.lavozdegalicia.es/noticia/ourense/san-xoan-de-rio/2023/12/02/premian-sostenibilidad-cooperativa-castanas-amarelante/0003_202312O2C1991.htmhttps://www.lavozdegalicia.es/noticia/ourense/san-xoan-de-rio/2023/12/02/premian-sostenibilidad-cooperativa-castanas-amarelante/0003_202312O2C1991.htm",
    },
    {
      title:
        "Gala Empresas Ourensanas 2023 | 'Los empresarios somos los que creamos riqueza y oportunidades'",
      content:
        "La sostenibilidad fue el eje central de la Gala Empresas Ourensanas 2023 de la CEO. Más de 200 personas acudieron ayer a la Gala de las Empresas Ourensanas organizada por la Confederación Empresarial de Ourense (CEO) en la que se reconoce la labor de firmas de la provincia en...",
      date: "02 " + t("month.dec"),
      year: "2023",
      image:
        "https://estaticos-cdn.prensaiberica.es/clip/46e7a6e5-5172-4c9a-8465-519b640425cf_21-9-aspect-ratio_default_0.jpg",
      btnClass: "btn",
      url: "https://www.laregion.es/articulo/ourense/gala-empresas-ourensanas-2023-empresarios-somos-que-creamos-riqueza-oportunidades/202312020538011258504.html",
    },
    {
      title:
        "Tecnologías y rural: ¿Cómo se enfrentan al cambio las entidades locales con menos recursos? ",
      content:
        "La tecnología creada en entornos urbanos no cubre realidades y necesidades rurales, hay problemas en la implementación de soluciones tecnológicas (cobertura, acceso wifi, domótica…) y aunque 'sobran' cursos de digitalización, no se adaptan a las necesidades y realidad del mundo rural.",
      date: "27 Nov",
      year: "2023",
      image:
        "https://periodicopueblos.com/upload/images/11_2023/1594_captura-de-pantalla-2023-11-24-161132.png",
      btnClass: "btn",
      url: "https://periodicopueblos.com/art/1883/tecnologias-y-rural-como-se-enfrentan-al-cambio-las-entidades-locales-con-menos-recursos",
    },
    {
      title: "Aldealista en Hora Galega na CRTVG ",
      content:
        "Reportaxe de Aldealsita no programa da TVG 'Hora Galega', do minuto 16 ao 22.",
      date: "17 Nov",
      year: "2023",
      image: "https://www.aldealista.com/news/crtvg-17-nov.jpg",
      btnClass: "btn",
      url: "https://www.crtvg.es/tvg/a-carta/programa-258-6319592?t=1028",
    },
    {
      title:
        "Juan Carlos Pérez: «Aldealista, o tinder dos pobos xa chega a 51 países» ",
      content:
        "O fundador da startup cumpre así o encargo que lle fixo a súa avoa antes de morrer e que era non deixar morrer as aldeas.",
      date: "15 Nov",
      year: "2023",
      image: "https://www.aldealista.com/news/google-cloud.jpg",
      btnClass: "btn",
      url: "https://www.somoscomarca.es/articulo/terras-trives/juan-carlos-perez-aldealista-tinder-pobos-xa-chega-51-paises/20231115180025162985.html",
    },
    {
      title: "Aldealista, un caso de éxito basado en Tinder",
      content:
        "La Axencia Galega de Innovación reconoce el acierto en el proceso de innovación de Aldealista, una pequeña empresa de O Castiñeiro, aldea de Río que llevaba diez años sin vecinos, en la creación de su plataforma digital pensada para en rural.",
      date: "15 Nov",
      year: "2023",
      image:
        "https://www.laregion.es/asset/thumbnail,1920,1080,center,center/media/laregion/images/2023/11/14/2023111422594724517.jpg",
      btnClass: "btn",
      url: "https://www.laregion.es/articulo/trives/aldealista-caso-exito-basado-tinder/202311150600331255471.html#",
    },
    {
      title:
        "El triunfo de Aldealista, el Tinder de los pueblos, que encuentra nuestra Itaca para ver o vivir",
      content:
        "La Xunta elogia el “éxito innovador” de esta plataforma digital que visibiliza las aldeas invisibles del rural. Desde una minúscula aldea de Ourense vende, al resto del mundo, lugares adaptados al perfil de cada usuario. Ha tenido este año ya 250.000 visitas de 51 paises.",
      date: "15 Nov",
      year: "2023",
      image:
        "https://estaticos-cdn.prensaiberica.es/clip/bbc1c449-4787-4216-8a03-4e3e326cb218_21-9-aspect-ratio_default_0.jpg",
      btnClass: "btn",
      url: "https://www.farodevigo.es/ourense/2023/11/15/triunfo-aldealista-tinder-pueblos-encuentra-94630486.amp.html",
    },
    {
      title: "Reportaxe de Aldealista na Axencia Galega da Innovación",
      content:
        "Reportaxe sobre o éxito de Aldealista elaborado pola Axencia Galega da Innovación e a Xunta de Galicia. ",
      date: "12 Nov",
      year: "2023",
      image:
        "https://media.quincemil.com/imagenes/2023/11/11122003/captura-de-pantalla-2023-11-11-a-las-11-19-39-1024x539.jpg",
      btnClass: "btn",
      url: "https://www.aldealista.com/news/REPORTAXE ALDEALISTA_GAIN.pdf",
    },
    {
      title:
        "Así es el día a día de los pueblos de Galicia en peligro de extinción",
      content:
        "A pesar de los datos: el rural se resiste a morir en el corazón de Galicia. Y detrás de ello se encuentran proyectos tan interesantes como el de Aldealista, el 'tinder' de los pueblos que busca impulsar el retorno demográfico y la atracción de visitantes a las aldeas gallegas más invisibles. ",
      date: "12 Nov",
      year: "2023",
      image:
        "https://media.quincemil.com/imagenes/2023/11/11122003/captura-de-pantalla-2023-11-11-a-las-11-19-39-1024x539.jpg",
      btnClass: "btn",
      url: "https://www.elespanol.com/quincemil/articulos/actualidad/asi-es-el-dia-a-dia-de-los-pueblos-de-galicia-en-peligro-de-extincion?amp=1",
    },
    {
      title: "Aldealista no programa 'Xuntos en directo' da TVG",
      content:
        "Reportaxe sobre Aldealista no programa 'Xuntos en directo' da Televisón de Galicia, a partir de 1h 34min.",
      date: "5 Nov",
      year: "2023",
      image:
        "https://www.crtvg.es/files/full/e3b8d146-46e3-4901-8ad8-0edeed4522be.png",
      btnClass: "btn",
      url: "http://www.crtvg.es/tvg/a-carta/programa-8-6305456",
    },
    {
      title:
        "San Xoán de Río viaja a Castilla-La Mancha para presentar sus modelos innovadores",
      content:
        "En la intervención de Xosé Miguel Pérez, alcalde y Diputado de nuevas tecnologías y reto demográfico de la Diputación de Ourense, presentó el ejemplo de San Xoán de Río como municipio pionero en desarrollo de modelos innovadores así como aplicaciones para el apoyo al mundo rural como Aldealista",
      date: "24 Oct",
      year: "2023",
      image: "https://www.aldealista.com/news/photo_2023-10-24_11-39-55.jpg",
      btnClass: "btn",
      url: "https://www.somoscomarca.es/articulo/terras-trives/san-xoan-rio-viaja/20231024111944161574.html",
    },
    {
      title:
        "Abenójar acoge la I Jornada de Comunidades Energéticas con éxito de participación",
      content:
        "José Miguel Pérez, Diputado de nuevas tecnologías y reto demográfico de la Diputación de Ourense presentó el ejemplo de San Xoán de Río en el que es alcalde, como municipio pionero en desarrollo de modelos innovadores así como aplicaciones para el apoyo al mundo rural como Aldealista.",
      date: "23 Oct",
      year: "2023",
      image:
        "https://imagenes2.fotos.europapress.es/preview/5519807.webp?s=1000",
      btnClass: "btn",
      url: "http://www.crtvg.es/tvg/a-carta/conecemos-a-aplicacion-aldealista-un-xeito-de-atopar-a-aldea-da-que-nos-imos-namorar-6272741",
    },
    {
      title:
        "Coñecemos a aplicación Aldealista, un xeito de atopar a aldea da que nos imos namorar (A Revista TVG)",
      content:
        "Entrevista en plató a Juan Carlos Pérez no programa A Revista da Televisión de Galicia sobre o proxecto de Aldealista (min. 46 ó 53).",
      date: "05 Oct",
      year: "2023",
      image:
        "https://www.crtvg.es/files/web/b55a2b9b-afaa-4933-a9f2-a56feca6be8d.png",
      btnClass: "btn",
      url: "http://www.crtvg.es/tvg/a-carta/conecemos-a-aplicacion-aldealista-un-xeito-de-atopar-a-aldea-da-que-nos-imos-namorar-6272741",
    },
    {
      title:
        "Emprender en el rural: la cosmética natural de Piel Natura encuentra su refugio en Galicia",
      content:
        "Los fundadores de la marca alicantina decidieron cambiar la costa de Benidorm por San Xoán de Río a través de Aldealista para seguir mejorando su línea de productos y contribuir al mismo tiempo a dinamizar el rural de Ourense.",
      date: "01 Oct",
      year: "2023",
      image:
        "https://media.quincemil.com/imagenes/2023/09/30151052/foto-1-1-1-1706x960.jpg",
      btnClass: "btn",
      url: "https://www.elespanol.com/quincemil/articulos/economia/emprender-en-el-rural-la-cosmetica-natural-de-piel-natura-encuentra-su-refugio-en-galicia",
    },
    {
      title: "Aldealista en Aquí hay trabajo (rtve)",
      content:
        "Reportaje sobre Aldealista con entrevista a Juan Carlos Pérez en el programa de rtve Aquí hay trabajo (a partir del minuto 6).",
      date: "29 Sep",
      year: "2023",
      image: "https://img2.rtve.es/v/6864712/square/?h=400",
      btnClass: "btn",
      url: "https://www.rtve.es/play/videos/aqui-hay-trabajo/29-09-23/6978232/",
    },

    {
      title: "“Aldealista” muestra su proyecto en Toledo",
      content:
        "El Palacio de Congresos de Eurocaja Rural en Toledo fue el escenario de un coloquio trascendental, destacando como parte fundamental de la discusión la iniciativa “Empuéblate”.",
      date: "23 Sep",
      year: "2023",
      image:
        "https://www.laregion.es/asset/thumbnail,1920,1080,center,center/media/laregion/images/2023/09/23/2023092316472779777.jpg",
      btnClass: "btn",
      url: "https://www.laregion.es/articulo/trives/aldealista-muestra-proyecto-toledo/202309231648181245643.html",
    },

    {
      title: "Entrevista a Juan Carlos en Hoy por Hoy Toledo",
      content:
        "Juan Carlos Pérez Rodríguez entrevistado en Hoy por Hoy Toledo tras la presentación de Aldealista en 'Empuéblate' (a partir del minuto 32).",
      date: "22 Sep",
      year: "2023",
      image:
        "https://recursosweb.prisaradio.com/fotos/original/010002997716.jpg",
      btnClass: "btn",
      url: "https://cadenaser.com/audio/ser_toledo_hoyporhoytoledo_20230922_122000_140000/",
    },

    {
      title:
        "Empuéblate, el epicentro del debate de la despoblación vuelve a Toledo",
      content:
        "Esta tercera edición del Empuéblate reúne a diferentes administraciones, así como emprendedores que han apostado por los municipios del mundo rural para establecer sus negocios. Un ejemplo de ello es Aldealista, que funciona como una aplicación móvil de citas. Sin embargo, en este caso, como relataba su CEO, Juan Carlos Pérez, se hacía match con diferentes municipios que, actualmente, sufren despoblación o pérdida exponencial de habitantes.",
      date: "22 Sep",
      year: "2023",
      image:
        "https://cadenaser.com/resizer/URjI9sX1XdC0MixsB9GzYM0RTf0=/990x557/filters:format(jpg):quality(70)/cloudfront-eu-central-1.images.arcpublishing.com/prisaradio/MG5MZ22MSFGJZCMLXW7LTKAALU.jpeg",
      btnClass: "btn",
      url: "https://cadenaser.com/castillalamancha/2023/09/22/empueblate-el-epicentro-del-debate-de-la-despoblacion-vuelve-a-toledo-ser-toledo/?outputType=amp",
    },

    {
      title: "Aldealista, unha aplicación que promove o amor polo rural galego",
      content:
        "Unha aplicación para o móbil lanzada desde unha aldea do Concello ourensán de San Xoán de Río permite conectar as persoas que queren unha casa no rural coas localidades que buscan novos veciños.",
      date: "20 Sep",
      year: "2023",
      image: "https://www.crtvg.es/files/full/G24recortada.jpg",
      btnClass: "btn",
      url: "https://www.g24.gal/-/aldealista-unha-aplicacion-que-promove-o-amor-polo-rural-galego",
    },
    {
      title:
        "Antes de morir, su abuela le pidió que hiciese algo por los pueblos. Así nació Aldealista",
      content:
        "Algunos comparan Aldealista con el 'Tinder' de los pueblos, aunque en realidad es una plataforma para dar visibilidad a los municipios con menos de 5.000 habitantes, además de ayudar en su digitalización.",
      date: "19 Sep",
      year: "2023",
      image:
        "https://www.finanziaconnect.com/wp-content/uploads/2016/12/logo-emprendedores-tra-480.png",
      btnClass: "btn",
      url: "https://www.aldealista.com/news/aldealista-emprendedores-309.pdf",
    },
    {
      title:
        "Un 'Tinder' para encontrar tu pueblo ideal: así funciona 'Aldealista', una curiosa app desarrollada en Galicia",
      content:
        "Aldealista, concebida “para enamorarse del rural”, fue finalista de los premios European Technology Awards.",
      date: "07 Sep",
      year: "2023",
      image:
        "https://fotografias.antena3.com/clipping/cmsimages02/2023/09/07/F9373E9F-7130-4578-9149-A5A01EFBE37D/juan-carlos-desarrollador-aldealista_98.jpg?crop=1024,576,x0,y216&width=1900&height=1069&optimize=low&format=webply",
      btnClass: "btn",
      url: "https://www.antena3.com/noticias/sociedad/tinder-encontrar-pueblo-ideal-asi-funciona-aldealista-curiosa-app-desarrollada-galicia_2023090764fa008483dfa50001891122.html",
    },
    {
      title: "Así funciona el «Tinder de los pueblos» ideado por un gallego",
      content:
        "Una aplicación lanzada desde una aldea ourensana busca conectar a los que quieren mudarse al rural con localidades que buscan vecinos",
      date: "04 Sep",
      year: "2023",
      image:
        "https://cflvdg.avoz.es/sc/PBAqGyDvUdQgu7FoAv9WZT2NCfc=/768x/2022/06/16/00121655371621644667631/Foto/O15J2053.jpg",
      btnClass: "btn",
      url: "https://www.lavozdegalicia.es/noticia/ourense/san-xoan-de-rio/2023/09/01/funciona-tinder-pueblos/00031693588261586744173.htm",
    },
    {
      title:
        "A web galega Aldealista, finalista dos premios European Technology Awards",
      content:
        "Aldealista é una app galega concibida coma un Tinder pero 'para namorar do rural'",
      date: "07 " + t("month.jul"),
      year: "2023",
      image: "https://www.crtvg.es/files/full/G24recortada.jpg",
      btnClass: "btn",
      url: "https://www.g24.gal/-/a-web-galega-aldealista-finalista-dos-premios-european-technology-awards",
    },
    {
      title: "¿Sabe por qué San Xoán de Río opta a un premio europeo?",
      content:
        "Aldealista podría hacerse con un galardón en los European Technology Awards (Premios Europeos de Tecnología) en la categoría de app.",
      date: "06 " + t("month.jul"),
      year: "2023",
      image:
        "https://www.laregion.es/asset/thumbnail,768,432,center,center/media/laregion/images/2023/07/06/2023070609160672088.jpg",
      btnClass: "btn",
      url: "https://www.laregion.es/articulo/quen-cho-dixo/sabe-que-san-xoan-rio-opta-premio-europeo/202307060917531231723.amp.html",
    },
    {
      title:
        "Aldealista, la app de los pueblos de Galicia, candiBLOG_DATA a los European Technology Awards",
      content:
        "La aplicación buscar poner en valor el rural gallego y atraer gente a las pequeñas aldeas de nuestra comunidad.",
      date: "06 " + t("month.jul"),
      year: "2023",
      image:
        "https://media.quincemil.com/imagenes/2023/07/06160111/whatsapp-image-2022-09-27-at-13-31-36-1_photos_v2_x2-1706x960.jpg",
      btnClass: "btn",
      url: "https://www.elespanol.com/quincemil/articulos/economia/aldealista-la-app-de-los-pueblos-de-galicia-candiBLOG_DATA-a-los-european-technology-awards",
    },
    {
      title:
        " «Aldealista», candiBLOG_DATA al European Technology Awards, en la categoría de APP",
      content:
        "Esta aplicación de la España Rural lucha contra la despoblación y pretende «hacer visibles los pueblos invisibles»",
      date: "04 " + t("month.jul"),
      year: "2023",
      image:
        "https://www.somoscomarca.es/asset/thumbnail,992,558,center,center/media/somoscomarca/images/2022/10/01/2022100114042049114.jpg",
      btnClass: "btn",
      url: "https://www.somoscomarca.es/articulo/actualidad/aldealista-candiBLOG_DATA-european-technology-awards-categoria-app/20230704200311154068.html",
    },
    {
      title:
        "'Aldealista', a candidate for the European Technology Awards, in the APP category",
      content:
        "The app that got up in a small village of San Xoán de Río, in Castineiro, from the hand of Juan Carlos Pérez, and who began to walk with the purpose of give visibility to rural life, adds a new receipt",
      date: "04 " + t("month.jul"),
      year: "2023",
      image:
        "https://www.somoscomarca.es/asset/thumbnail,992,558,center,center/media/somoscomarca/images/2022/10/01/2022100114042049114.jpg",
      btnClass: "btn",
      url: "https://journaltime.org/2023/07/04/aldealista-a-candidate-for-the-european-technology-awards-in-the-app-category/",
    },
    {
      title:
        "Poco antes de morir, con 102 años, su abuela le pidió que hiciese algo por los pueblos. Así nació Aldealista",
      content:
        "Algunos comparan Aldealista con el ‘tinder’ de los pueblos, aunque en realidad es una plataforma que persigue dar visibilidad a los municipios con menos de 5.000 habitantes además de ayudarles en su proceso de digitalización",
      date: "25 " + t("month.jun"),
      year: "2023",
      image:
        "https://b7q5h9f5.rocketcdn.me/wp-content/uploads/2023/05/Juan-Carlos-Perez-Aldealista-1024x576.jpg",
      btnClass: "btn",
      url: "https://www.emprendedores.es/ideas-de-negocio/aldealista-pueblos/",
    },
    {
      title:
        "De Benidorm a Terra de Trives para mejorar su línea de cosmética natural",
      content:
        "Dos emprendedores valencianos quieren explorar las posibilidades de las plantas medicinales de San Xoán de Río",
      date: "28 " + t("month.may"),
      year: "2023",
      image:
        "https://cflvdg.avoz.es/sc/ANltiWRGue4uOYlST58wCq3i7aY=/768x/2023/05/27/00121685211762642803812/Foto/2.jpg",
      btnClass: "btn",
      url: "https://www.lavozdegalicia.es/noticia/sociedad/2023/05/28/benidorm-terra-trives-mejorar-linea-cosmetica-natural/0003_202305G28P37993.htm",
    },
    {
      title:
        "Cambian Benidorm por San Xoán de Río para o seu proxecto de cosmética natural",
      content:
        "En setembro vanse instalar nunha casa con horta, onde van experimentar con extractos de castiñeiro e plantas medicinais autóctonas.",
      date: "25 " + t("month.may"),
      year: "2023",
      image:
        "https://img-g24-crtvg.flumotion.com/prog24/2023/20230524/DEBENIDORMASANXOANDERIOPARAPRODUCIRCOSMETICOSNATURAIS_frame_170.png",
      btnClass: "btn",
      url: "https://www.g24.gal/-/cambian-benidorm-por-san-xoan-de-rio-en-ourense-para-o-seu-proxecto-de-cosmetica-natural?p_l_back_url=%2Fsearch%3Fq%3DCosmetica%2Bnatural",
    },
    {
      title: "Lígate a una aldea (Reportaje RTVE)",
      content:
        "Las aldeas de Galicia buscan habitantes. Juan Carlos ha creado una app para atraer a turistas y repoblar aldeas y pueblos de municipios con menos de 5000 habitantes. La app está disponible en 20 países. Funciona como una app de citas, pero, en esta, el match surge cuando encuentras a tu pueblo ideal.",
      date: "24 " + t("month.may"),
      year: "2023",
      image: "https://img2.rtve.es/v/6897127",
      btnClass: "btn",
      url: "https://www.rtve.es/play/videos/programa/aplicacion-para-encontrar-tu-pueblo-ideal-ligate-aldea/6897127/",
    },
    {
      title: "De Benidorm a San Xoán de Río para producir cosméticos naturales",
      content:
        "Una empresa valenciana de cosméticos y sin envases plásticos, Pielnatura, ha elegido el municipio de San Xoán de Río para establecerse. Sus propietarios alquilaron una casa que ocuparán en septiembre, y en la que pasarán de hacer una cosmética natural mediterránea a una cosmética termal ourensana.",
      date: "23 " + t("month.may"),
      year: "2023",
      image:
        "https://estaticos-cdn.prensaiberica.es/clip/e04170fe-364e-4dcd-9572-f7fd25686eb0_21-9-aspect-ratio_default_0_x842y897.jpg",
      btnClass: "btn",
      url: "https://www.farodevigo.es/ourense/2023/05/23/benidorm-san-xoan-rio-producir-87746625.amp.html",
    },
    {
      title:
        "De aplicaciones para el ganado a economía circular: Nuevas oportunidades de negocio en la España vaciada",
      content:
        "En España contamos con un total de 8.131 municipios de los cuales 6.827 tienen menos de 5.000 habitantes. Esto significa que el 80% de la población nacional se concentra en un 20% del territorio, mientras que el resto lo estamos abandonando. Estos son al menos los datos que facilita Juan Carlos Pérez, cofundador y CEO de Aldealista, una aplicación para descubrir pueblos con menos de 5.000 habitantes que funciona como una aplicación de citas, de aquí que algunos se refieran a ella como el tinder de los pueblos.",
      date: "22 " + t("month.may"),
      year: "2023",
      image:
        "https://valenciaplaza.com/public/Image/2023/5/Exxitainstalaciones_NoticiaAmpliada.jpg",
      btnClass: "btn",
      url: "https://valenciaplaza.com/nuevas-oportunidades-de-negocio-en-la-espana-vaciada",
    },
    {
      title:
        "Juan Carlos Pérez, creador de Aldealista: «Nuestra aplicación para conocer pueblos funciona como las aplicaciones de citas»",
      content:
        "Pérez presentará este viernes en Folgoso do Courel una herramienta digital creada para dar visibilidad a pequeñas localidades rurales",
      date: "05 " + t("month.may"),
      year: "2023",
      image:
        "https://cflvdg.avoz.es/sc/kAJhCF0MtRdeaiQIwAvoXkYmj_8=/768x/2023/05/04/00121683217261639463996/Foto/MY5C2F1_181913.jpg",
      btnClass: "btn",
      url: "https://www.lavozdegalicia.es/amp/noticia/ourense/san-xoan-de-rio/2023/05/05/aplicacion-conocer-pueblos-funciona-aplicaciones-citas/0003_202305M5C2991.htm",
    },
    {
      title:
        "Aldealista, el proyecto digital que lucha por el reto demográfico desde Ourense a Lugo.",
      content:
        "El proyecto digital “Aldealista”, nacido en O Castiñeiro, una pequeña aldea de San Xoán de Río, estuvo presente en unas jornadas celebradas en la Deputación de Lugo, en las cuales contaron con la presencia de Manuel Campo Vidal.",
      date: "26 Abr",
      year: "2023",
      image:
        "https://www.laregion.es/asset/thumbnail,768,432,center,center/media/laregion/images/2023/04/25/2023042522262723684.jpg",
      btnClass: "btn",
      url: "https://www.laregion.es/articulo/ourense/aldealista-proyecto-digital-que-lucha-reto-demografico-ourense-lugo/202304252226351215351.amp.html",
    },
    {
      title:
        "Juan Carlos Pérez: «Manuel Campo Vidal se ha hecho un ‘Aldealista’».",
      content:
        "El periodista, Manuel Campo Vidal y Juan Carlos Pérez compartieron propósito en la jornada de reto demográfico celebrada en la Diputación de Lugo.",
      date: "22 Abr",
      year: "2023",
      image:
        "https://www.somoscomarca.es/asset/thumbnail,992,558,center,center/media/somoscomarca/images/2023/04/22/2023042213302342994.jpg",
      btnClass: "btn",
      url: "https://www.somoscomarca.es/articulo/terras-trives/juan-carlos-perez-manuel-campo-vidal-ha-hecho-aldealista/20230422133653149528.html",
    },
    {
      title:
        "Empresas, universidad y concellos piden unir fuerzas frente al reto demográfico.",
      content:
        "La entidad provincial también dio a conocer el proyecto Aldealista, una plataforma digital que busca 'dixitalizar e facer visible as aldeas', tal y como explicó su creador, Juan Carlos Pérez, de San Xoán de Río, en Ourense.",
      date: "21 Abr",
      year: "2023",
      image:
        "https://www.elprogreso.es/asset/thumbnail,768,432,center,center/media/elprogreso/images/2023/04/21/2023042117285593106.jpg",
      btnClass: "btn",
      url: "https://www.lavozdegalicia.es/amp/noticia/lugo/2023/04/20/vivir-lugo-programa-busca-asentar-poblacion-rural/00031682015273423598244.htm",
    },
    {
      title:
        "«E para vivir Lugo», el programa que busca asentar población en el rural.",
      content:
        "La Diputación dio a conocer el proyecto Aldealista, una plataforma digital de la que forma parte para dar visibilidad a los atractivos turísticos de las aldeas lucenses de los concellos de menos de 5.000 habitantes. Su creador, Juan Carlos Pérez, explicó la funcionalidad de esta herramienta, que cuenta en la actualidad con la presencia de 209 municipios gallegos.",
      date: "21 Abr",
      year: "2023",
      image:
        "https://cflvdg.avoz.es/sc/V44kb1Hr1ghM5bDuvSaITPPka5E=/768x/2023/04/20/00121682015341095446358/Foto/L20A3033.jpg",
      btnClass: "btn",
      url: "https://www.lavozdegalicia.es/amp/noticia/lugo/2023/04/20/vivir-lugo-programa-busca-asentar-poblacion-rural/00031682015273423598244.htm",
    },
    {
      title:
        "Juan Carlos Pérez e Aldealista na xornada sobre o reto demográfico (Telexornal RTVE)",
      content:
        "Juan Carlos Pérez e Aldealista na xornada sobre o reto demográfico celebradas pola diputación de Lugo (a partir do minuto 11:13).",
      date: "20 Abr",
      year: "2023",
      image: "https://img2.rtve.es/v/6836000/vertical/?h=400",
      btnClass: "btn",
      url: "https://www.rtve.es/play/videos/telexornal-galicia/segunda-edicion-20-04-2023/6868721/",
    },
    {
      title:
        "La Diputación organiza un debate para conocer propuestas frente al reto demográfico ",
      content:
        "Se dará a conocer el trabajo de la iniciativa Aldealista, del que es responsable Juan Carlos Pérez, y de la que forma parte la institución provincial.",
      date: "17 Abr",
      year: "2023",
      image:
        "https://www.elprogreso.es/asset/thumbnail,768,432,center,center/media/elprogreso/images/2023/04/14/2023041412020258361.jpg",
      btnClass: "btn",
      url: "https://www.elprogreso.es/articulo/lugo/diputacion-organiza-debate-conocer-propuestas-sector-publico-privado-frente-reto-demografico/202304171853581657623.amp.html",
    },
    {
      title:
        "La Diputación de Lugo organiza un debate para conocer propuestas del sector público y privado frente al reto demográfico",
      content:
        "Se dará a conocer el trabajo de la iniciativa 'Aldealista', del que es responsable Juan Carlos Pérez, y de la que forma parte la institución provincial.",
      date: "17 Abr",
      year: "2023",
      image: "https://www.galiciapress.es/images/showid/1167817",
      btnClass: "btn",
      url: "https://www.galiciapress.es/texto-diario/amp/4255022/diputacion-lugo-organiza-debate-conocer-propuestas-sector-publico-privado-frente-reto-demografico",
    },
    {
      title: "Aldealista, la App gallega contra la despoblación",
      content:
        "Hablamos con con Juan Carlos Pérez, del proyecto Aldealista, que se define como la aplicación de la España Desaprovechada, que llega para empoderar y convertirse en La Resistencia de la España Vaciada.",
      date: "16 Abr",
      year: "2023",
      image:
        "https://mundoruralenpositivo.com/wp-content/uploads/2023/04/aldealista.jpg",
      btnClass: "btn",
      url: "https://mundoruralenpositivo.com/aldealista/",
    },
    {
      title: "Entrevista a Juan Carlos Pérez en RTVE",
      content:
        "Dar visibilidad a los pueblos de la España 'desaprovechada'. Ese es el objetivo de Aldealista, una aplicación creada por el orensano Juan Carlos Pérez que es conocida ya como el 'Tinder' de los pueblos en Galicia",
      date: "10 Abr",
      year: "2023",
      image: "https://img2.rtve.es/a/6859031/square/?h=320",
      btnClass: "btn",
      url: "https://www.rtve.es/play/audios/reportajes-emisoras/reportajes-emisoras-orense-aldealista/6859031/",
    },
    {
      title: "Reportaxe sobre Aldealista no Telexornal de RTVE",
      content:
        "Na localidade ourensá de San Xoan de Río, unha aplicación de móbil axuda a persoas de todo o mundo interesadas en establecerse en núcleos despoboados. ",
      date: "15 Mar",
      year: "2023",
      image: "https://img2.rtve.es/v/6836000/vertical/?h=400",
      btnClass: "btn",
      url: "https://www.rtve.es/play/videos/telexornal-galicia/segunda-edicion-15-03-2023/6836000/",
    },
    {
      title:
        "Arte contra la despoblación rural: los recursos naturales de una aldea gallega llegan a París",
      content:
        "Joaquín Balsa ha creado una colección de pequeñas esculturas hechas con madera, oro y cuarcita de San Xoán de Río (Ourense) que desde hoy se exponen en París, y que lo harán también en Seúl o Nueva York",
      date: "01 Mar",
      year: "2023",
      image:
        "https://media.quincemil.com/imagenes/2023/03/01113103/befunky-collage-2023-03-01t102942-778-1706x960.jpg",
      btnClass: "btn",
      url: "https://www.elespanol.com/quincemil/articulos/cultura/arte-contra-la-despoblacion-rural-los-recursos-naturales-de-una-aldea-gallega-llegan-a-paris?amp=1",
    },
    {
      title:
        "«#Artecontraladespoblación», de San Xoán de Río a galerías de arte de París y Seúl",
      content:
        "Aldealista y la Fundación Joaquín Balsa luchan contra la despoblación con piezas de arte únicas y exclusivas  hechas de recursos naturales del municipio de San Xoán de Río",
      date: "26 Feb",
      year: "2023",
      image:
        "https://www.somoscomarca.es/asset/thumbnail,768,432,center,center/media/somoscomarca/images/2023/02/26/2023022620344084334.jpg",
      btnClass: "btn",
      url: "https://www.somoscomarca.es/articulo/terras-trives/san-xoan-rio-galerias-arte-paris-seul/20230226103321146056.amp.html",
    },
    {
      title: "Arte contra o despoboamento dende San Xoán de Río",
      content:
        "Arte contra o despoboamento, é a idea coa que traballan nunha pequena aldea do concello de San Xoán de Río. Unhas esculturas elaboradas con materias primas da zona que próximamente se van expoñer en lugares como París, Seúl ou Nova York.",
      date: "24 Feb",
      year: "2023",
      image: "https://www.crtvg.es/files/full/G24recortada.jpg",
      btnClass: "btn",
      url: "https://www.g24.gal/-/arte-contra-o-despoboamento-dende-san-xoan-de-rio?p_l_back_url=/search?q=Arte+contra+o+despoboamento",
    },
    {
      title: "Esculturas de arte natural, de San Xoán de Río a París e Seul",
      content:
        "Unha singular loita contra a despoboación nun proxecto creado entre a Fundación Joaquín Balsa e Aldealista, leva a San Xoán de Río a galerías de arte en grandes lugares como París ou Seul.",
      date: "24 Feb",
      year: "2023",
      image:
        "https://estaticos-cdn.prensaiberica.es/clip/3dd9c5e2-0322-4898-8e87-ec3f65e78294_16-9-discover-aspect-ratio_default_0_x549y959.jpg",
      btnClass: "btn",
      url: "https://www.laregion.es/articulo/trives/esculturas-arte-natural-van-galerias-paris-seul/202302232110501201271.amp.html",
    },
    {
      title: "Esculturas naturales que rescatan pueblos",
      content:
        "Madera de castaño de la aldea de Castiñeiro, el oro de los Biocos y la cuarcita, son percibidas como una solución cultural al abandono rural en San Xoán de Río. Un proyecto de la Fundación Joaquín Balsa - Aldealista - Concello consiste en hacer un Museo - Escuela de Arte Taller en Castiñeiro porque “con más artistas los pueblos no pueden desaparecer”, y aprovechando los recursos de este municipio, hacen una exposición que cruzará fronteras.",
      date: "23 Feb",
      year: "2023",
      image:
        "https://estaticos-cdn.prensaiberica.es/clip/3dd9c5e2-0322-4898-8e87-ec3f65e78294_16-9-discover-aspect-ratio_default_0_x549y959.jpg",
      btnClass: "btn",
      url: "https://www.farodevigo.es/ourense/2023/02/23/esculturas-naturales-rescatan-pueblos-83496965.amp.html",
    },
    {
      title: "Dende San Xoán de Río a galerías de arte de París e Seúl",
      content:
        "Aldealista e a Fundación Joaquín Balsa lanzan unha iniciativa contra o despoboamento con pezas de arte 'únicas e exclusivas feitas de recursos naturais deste municipio'.",
      date: "22 Feb",
      year: "2023",
      image: "https://osil.info/wp-content/uploads/2023/02/sanxo2.jpg",
      btnClass: "btn",
      url: "https://osil.info/dende-san-xoan-de-rio-a-galerias-de-arte-de-paris-e-seul/",
    },
    {
      title: "Entrevista a Juan Carlos en el podcast La Trilla",
      content:
        "Juan Carlos es entrevistado en el podcast de La Trilla donde nos habla de Aldealista.",
      date: "04 Feb",
      year: "2023",
      image: "https://cdn-profiles.tunein.com/p1190867/images/logog.png",
      btnClass: "btn",
      url: "https://www.ivoox.com/trilla-audios-mp3_rf_102547238_1.html?ts=2888",
    },
    {
      title:
        "Descripción de Juan Carlos y Aldealista, su 'Tinder' de los pueblos",
      content:
        "Juan Carlos ha vivido en varios lugares del mundo, como Noruega y Panamá. Pero la pandemia le llevó a quedarse en O Castiñeiro, una aldea gallega en la que solía pasar los veranos. Ahora vive allí y, desde su nuevo hogar se ha propuesto poner su granito de arena para dar visibilidad al medio rural.",
      date: "01 Feb",
      year: "2023",
      image:
        "https://media.quincemil.com/imagenes/2022/09/27204705/WhatsApp-Image-2022-09-27-at-13.34.15-640x360.jpeg",
      btnClass: "btn",
      url: "https://www.ivoox.com/juan-carlos-aldealista-su-tinder-los-audios-mp3_rf_102414389_1.html",
    },
    {
      title:
        'La Casa de Galicia en Madrid acoge la presentación de la plataforma Aldealista, el "Tinder de los pueblos gallegos"',
      content:
        "Juan Serrano, director de la Casa de Galicia en Madrid dio esta semana la bienvenida a la plataforma Aldealista, con Juan Carlos Pérez al frente, nacido en San Xoan de Río (Ourense).",
      date: "16 " + t("month.dec"),
      year: "2022",
      image: "https://www.xunta.gal/image/image_gallery?img_id=1891604",
      btnClass: "btn",
      url: "https://www.xunta.gal/notas-de-prensa/-/nova/75609/casa-galicia-madrid-acolle-presentacion-plataforma-aldealista-tinder-dos-pobos?langId=es_ES",
    },
    {
      title:
        'Los primeros municipios se instalan en "Aldealista" para encontrar nuevos "amores"',
      content:
        'La aplicación "Aldealista",  que pretenden poner en valor los 209 concellos gallegos con menos de 5.000 habitantes, se presentó en la Casa de Galicia de Madrid este miércoles.',
      date: "15 " + t("month.dec"),
      year: "2022",
      image:
        "https://www.somoscomarca.es/asset/thumbnail,768,432,center,center/media/somoscomarca/images/2022/12/15/2022121518012666431.jpg",
      btnClass: "btn",
      url: "https://www.xunta.gal/notas-de-prensa/-/nova/75609/casa-galicia-madrid-acolle-presentacion-plataforma-aldealista-tinder-dos-pobos?langId=es_ES",
    },
    {
      title: "Quen anda aí?",
      content: "Programa 609 - Quen anda aí? TVG.",
      date: "15 " + t("month.dec"),
      year: "2022",
      image:
        "https://cdn.sincroguia.tv/uploads/programs/q/u/e/quen-anda-ai-774104_GLG-72.jpg",
      btnClass: "btn",
      url: "https://www.crtvg.es/tvg/a-carta/programa-609-5919859",
    },
    {
      title:
        "'Aldealista', el Tinder de los pueblos de Galicia que recibe la bendición del Papa Francisco",
      content:
        "'Aldealista' es una plataforma digital que nació plena pandemia en la parroquia de San Xoan de Río (Orense) con la intención de poner en valor y visibilizar los 209 concellos gallegos de menos de 5.000 habitantes.",
      date: "15 " + t("month.dec"),
      year: "2022",
      image:
        "https://www.65ymas.com/uploads/s1/12/06/75/7/aldealista-el-tinder-de-los-pueblos-de-galicia-que-recibe-la-bendicio-n-del-papa-francisco_1_621x621.jpeg",
      btnClass: "btn",
      url: "https://amp.65ymas.com/sociedad/aldealista-tinder-pueblos-galicia-recibe-bendicion-papa-francisco_46130_102_amp.html",
    },
    {
      title: "Entrevista a Juan Carlos en Julia en la onda",
      content:
        "Entrevista a Juan Carlos en Julia en la onda el 14 de diciembre del 2022.",
      date: "14 " + t("month.dec"),
      year: "2022",
      image:
        "https://image.ondacero.es/clipping/cmsimages01/2022/02/17/AF657973-DCD3-44B7-963E-068BDAEBED30/julia-otero_69.jpg",
      btnClass: "btn",
      url: "https://www.ondacero.es/programas/julia-en-la-onda/programas-completos/julia-onda-14122022_20221214639a12ccfe32290001c59e6b.html",
    },
    {
      title:
        "'Aldealista', el Tinder de las aldeas gallegas que ha bendecido el Papa Francisco",
      content:
        "Juan Carlos Pérez, economista de 51 años, se ha enamorado de una pequeña aldea despoblada de Ourense. No llegó a ella por casualidad. Allí habían vivido sus abuelos y también había pasado los veranos de su infancia. Pero el flechazo definitivo se produjo cuando llegó la pandemia del coronavirus. ",
      date: "13 " + t("month.dec"),
      year: "2022",
      image:
        "https://album.mediaset.es/eimg/2022/12/13/juan-carlos-perez-ceo-de-aldealista_c069.jpg?w=1024",
      btnClass: "btn",
      url: "https://www.niusdiario.es/espana/galicia/20221213/aldealista-tinder-visibilizar-aldeas-gallegas-bendecido-papa-francisco_18_08213953.html",
    },
    {
      title:
        "'Bendición' vaticana a la aplicación para enamorarse de las aldeas rurales",
      content:
        "A comienzos de octubre enviaron una carta al Vaticano en la que hacían partícipe al Papa Francisco de su iniciativa para darle un futuro a los entornos rurales bajo el lema 'Hay un pueblo que se quiere enamorar de ti'. Su intención era «comunicarle que hay gente común intentando poner su grano de arena» frente a la despoblación, «motivando a otros y mostrando que en este medio se puede vivir bien, tener un futuro y trabajar para ello».",
      date: "12 " + t("month.dec"),
      year: "2022",
      image:
        "https://s3.abcstatics.com/abc/www/multimedia/espana/2022/12/12/DAMBin-REwUfDajrmso0JBmLRPtkoI-758x531@abc.jpg",
      btnClass: "btn",
      url: "https://www.lavozdigital.es/espana/galicia/bendicion-vaticana-aplicacion-enamorarse-aldeas-rurales-20221212103230-nt.html",
    },
    {
      title:
        "El 'Tinder' de los pueblos de Galicia recibe el apoyo del Vaticano",
      content:
        "El Papa Francisco envió una carta al fundador de esta app que nació en San Xoán de Río (Ourense) y que busca poner freno al abandono del rural. A partir del miércoles, estará disponible para todos los usuarios de Play Store y App Store.",
      date: "12 " + t("month.dec"),
      year: "2022",
      image:
        "https://media.quincemil.com/imagenes/2022/12/12121800/BeFunky-collage-90-1-1706x960.jpg",
      btnClass: "btn",
      url: "https://www.elespanol.com/quincemil/articulos/economia/el-tinder-de-los-pueblos-de-galicia-recibe-el-apoyo-del-vaticano?amp=1",
    },
    {
      title:
        "'Bendición' vaticana a la aplicación para enamorarse de las aldeas rurales",
      content:
        "A mediados de septiembre, a su regreso de un viaje apostólico a Kazajistán, el Papa Francisco lamentó en declaraciones a los periodistas que le acompañaban en su avión oficial que Occidente se hubiera olvidado de acoger a poblaciones procedentes de países en conflicto, mientras que «en España o en Italia existen pueblos vacíos con 20 viejecitos».",
      date: "12 " + t("month.dec"),
      year: "2022",
      image:
        "https://s3.abcstatics.com/abc/www/multimedia/espana/2022/12/12/DAMBin-REwUfDajrmso0JBmLRPtkoI-758x531@abc.jpg",
      btnClass: "btn",
      url: "https://www.abc.es/espana/galicia/bendicion-vaticana-aplicacion-enamorarse-aldeas-rurales-20221212103230-nt.html",
    },
    {
      title:
        'El Vaticano apoya el proyecto "Aldealista", una APP nacida en San Xoán de Río',
      content:
        'La aplicación "Aldealista",  que pretenden poner en valor los 209 concellos gallegos con menos de 5.000 habitantes, se presentará en la Casa de Galicia de Madrid el próximo miércoles, 14 de diciembre',
      date: "10 " + t("month.dec"),
      year: "2022",
      image:
        "https://www.somoscomarca.es/asset/thumbnail,768,432,center,center/media/somoscomarca/images/2022/12/10/2022121017561270345.jpg",
      btnClass: "btn",
      url: "https://www.somoscomarca.es/articulo/terras-trives/vaticano-apoya-proyecto-aldealista-nacido-san-xoan-rio/20221210175708140981.amp.html",
    },
    {
      title:
        "O noso algoritmo busca unha aldea que namore de ti segundo os teus patróns de gustos",
      content:
        'Juan Carlos Pérez, economista, é un dos fundadores de Aldealista, unha aplicación que pretende unir vilas, aldeas e persoas, pondo en valor o rural co obxectivo de evitar a perda de poboación. "Nós Diario" conversa con Pérez sobre o proceso de creación da plataforma e das iniciativas de apoio ao comercio local, xa que, como el di, "ninguén vai quedar nun lugar onde non ten traballo".',
      date: "18 Nov",
      year: "2022",
      image:
        "https://www.nosdiario.gal/asset/thumbnail,768,432,center,center/media/nosdiario/images/2022/11/18/2022111808110854283.jpg",
      btnClass: "btn",
      url: "https://www.nosdiario.gal/articulo/social/juan-carlos-perez-noso-algoritmo-busca-aldea-que-namore-ti-segundo-teus-patrons-gustos/20221118081001156722.amp.html",
    },
    {
      title: "Tecnología para hacer frente al abandono rural",
      content:
        "Juan Carlos Pérez, impulsor de Aldealista, la app que buscar terminar con la España Vaciada, explica las oportunidades de las nuevas tecnologías para poner en valor nuestros pueblos.",
      date: "05 Nov",
      year: "2022",
      image:
        "https://media.quincemil.com/imagenes/2022/09/27204705/WhatsApp-Image-2022-09-27-at-13.34.15-640x360.jpeg",
      btnClass: "btn",
      url: "https://www.elespanol.com/quincemil/articulos/economia/tecnologia/tecnologia-para-hacer-frente-al-abandono-rural",
    },
    {
      title:
        "Regreso a la España vaciada durante la pandemia: ¿oportunidad o coyuntura?",
      content:
        "Entre 2018 y 2021 los municipios de menos de 1.000 habitantes registraron un saldo positivo de habitandes, es decir, llegaron más de los que se fueron. Son datos  del estudio 'Movimientos de población hacia el medio rural' elaborado por el Ministerio de Transición Ecológica que contrastan con los datos registrados entre 2011 y 2017, en que los pueblos pequeños perdieron 104.526 habitantes.",
      date: "02 Nov",
      year: "2022",
      image:
        "https://alicanteplaza.es/public/Image/2022/11/espana_vaciada_NoticiaAmpliada.jpg",
      btnClass: "btn",
      url: "https://alicanteplaza.es/regreso-a-la-espana-vaciada-durante-la-pandemia-oportunidad-o-coyuntura",
    },
    {
      title: "Juan Carlos Pérez entrevista en RadioVoz",
      content:
        "Programa de Radio Voz del 1 de noviembre de 2022, hablando sobre Aldealista y su desarrollo.",
      date: "01 Nov",
      year: "2022",
      image: "https://euroespes.com/wp-content/uploads/2021/10/RadioVoz.jpg",
      btnClass: "btn",
      url: "https://youtu.be/o3oUlIDEWvo",
    },
    {
      title: "Quen anda aí?",
      content: "Programa 565 - Quen anda aí? TVG.",
      date: "14 Oct",
      year: "2022",
      image:
        "https://cdn.sincroguia.tv/uploads/programs/q/u/e/quen-anda-ai-774104_GLG-72.jpg",
      btnClass: "btn",
      url: "https://www.crtvg.es/tvg/a-carta/programa-565-5847375?t=3516",
    },
    {
      title: "Entrevista a Juan Carlos no podcast Galicia por Diante",
      content:
        "Entrevista a Juan Carlos sobre Aldealista no podcast Galicia por Diante da CRTVG.",
      date: "9 Oct",
      year: "2022",
      image:
        "https://www.crtvg.es/files/web/20210209230004_GailciapordianteFDSLuisOjeavictoriarodriguezCORDEFINITIVABANDAAZULABAIXO.png",
      btnClass: "btn",
      url: "https://www.crtvg.es/podcast_rg/0105/0105_20221008100400.mp3",
    },
    {
      title:
        "Aldealista, un 'Tinder' para dar visibilidade ás aldeas cun obxectivo final: fixar poboación",
      content:
        "O inicio do confinamento obrigado pola pandemia de Covid-19 sorprendeu a Juan Carlos Pérez Rodríguez na aldea abandonada de Castiñeiro, en San Xoán de Río, a terra dos seus avós, onde de cando en cando volvera para veranerar. Alí tivo que quedar ata que as fronteiras foron reabertas. O economista, nacido en Suíza de pais galegos, traballara toda a súa vida fóra de Galicia, desenvolvendo proxectos de software en Centroamérica ou Noruega, e de súpeto atopouse vivindo nun territorio practicamente despoboado, pero con núcleos e paisaxes de fermosura innegable aínda que moitas veces descoñecidas para a maioría e inaccesibles a través da web.",
      date: "10 Oct",
      year: "2022",
      image: "https://praza.gal/storage/uploads/aldealista-6efec0f.jpeg",
      btnClass: "btn",
      url: "https://praza.gal/ciencia-e-tecnoloxia/aldealista-un-tinder-para-dar-visibilidade-as-aldeas-cun-obxectivo-final-fixar-poboacion",
    },
    {
      title: "Unha aplicación para ligar coa vila dos teus soños",
      content:
        "Se atopar casa nunca foi doado, imaxinen dar coa súa vila ideal. A tecnoloxía sempre é unha aliada, e neste caso non ía ser menos. Así xorde a aplicación ‘Aldealista’, que pretende descubrir o fogar dos nosos soños, cun funcionamento ao xeito dunha aplicación de citas.",
      date: "08 Oct",
      year: "2022",
      image:
        "https://www.crtvg.es/files/web/20210209230004_GailciapordianteFDSLuisOjeavictoriarodriguezCORDEFINITIVABANDAAZULABAIXO.png",
      btnClass: "btn",
      url: "https://www.crtvg.es/rg/destacados/galicia-por-diante-fin-de-semana-galicia-por-diante-fin-de-semana-do-dia-08-10-2022-5839557",
    },
    {
      title: " Los pueblos ya tienen su Tinder ",
      content:
        "Pasar imágenes hacia la izquierda cuando no nos gusta y a la derecha cuando sí lo hace ya no solo supone estar eligiendo pareja, los pueblos que te gustaría conocer también se pueden seleccionar a golpe de match. esa es la idea sobre la que gira una nueva aplicación de “citas”, la de aldealista.com, si bien lejos de tener que ver con los ligues, la propuesta pasa por seleccionar un destino de los muchos pueblos pequeños gallegos que se han quedado en el olvido y buscan “novio/a”.",
      date: "02 Oct",
      year: "2022",
      image: "https://www.elidealgallego.com/images/showid2/5539551?w=900",
      btnClass: "btn",
      url: "https://www.elidealgallego.com/articulo/aturuxos/pueblos-tienen-tinder-3910848",
    },
    {
      title:
        'Aldealista, la APP en donde encontrar el "amor" con un pueblo del rural gallego',
      content:
        "Dar solución al abandono del rural gallego es la única y principal razón por la que ha nacido Aldealista, una App donde, en resumen, un pueblo se enamorará de ti. Similar a aplicaciones para ligar, como Tinder, esta idea surgió en una pequeña parroquia de San Xoan de Río, en Castiñeiro, de la mano de Juan Carlos Pérez.",
      date: "01 Oct",
      year: "2022",
      image:
        "https://www.somoscomarca.es/asset/thumbnail,992,558,center,center/media/somoscomarca/images/2022/10/01/2022100114024030474.jpg",
      btnClass: "btn",
      url: "https://www.somoscomarca.es/articulo/terras-trives/aldealista-encontrar-amor-pueblo/20220929105251136449.html",
    },
    {
      title:
        "Nace 'Aldealista', la aplicación de 'citas' de los pueblos de Galicia para dar visibilidad al rural",
      content:
        "Repoblar el rural gallego, ese es el objetivo con el que nace 'Aldealista', una plataforma digital que pone el foco en aquellos municipios de menos de 5.000 habitantes. Esta novedosa aplicación nace en San Xoán de Río, en Ourense, de la mano de Juan Carlos Pérez. ",
      date: "29 Sep",
      year: "2022",
      image:
        "https://estaticos-cdn.prensaiberica.es/clip/c9acff07-994c-401d-b9c0-2053b45dfea4_16-9-discover-aspect-ratio_default_0.jpg",
      btnClass: "btn",
      url: "https://www.farodevigo.es/galicia/2022/09/29/nace-aldealista-aplicacion-citas-pueblos-dv-76171446.amp.html",
    },
    {
      title: 'Aldealista: llega el "Tinder" de la España Vaciada',
      content:
        "En los últimos tiempos están saliendo muchas y diversas iniciativas para volver a visibilizar pueblos que se han ido desangrando en este siglo, y motivar a la gente joven para que vuelva a poblarlos. Desde, directamente, pagar a los nuevos moradores, hasta cooperativas o espacios de coworking, las ideas varían mucho, pero posiblemente, Aldealista es la más sorprendente. Tal y como la definen sus creadores, es una suerte de Tinder en donde no buscas enamorarte de una persona, sino de un pueblo entero.",
      date: "29 Sep",
      year: "2022",
      image:
        "https://estaticos-cdn.prensaiberica.es/clip/523cfa05-1c18-4101-aa49-20cf7ed9404e_source-aspect-ratio_default_0.jpg",
      btnClass: "btn",
      url: "https://viajar.elperiodico.com/planes/aldealista-tinder-pueblos-galicia-espana-vaciada",
    },

    {
      title:
        "Nace Aldealista, el 'Tinder' de los pueblos de Galicia que busca dar visibilidad al rural",
      content:
        "Esta pionera plataforma digital nace en San Xoán de Río (Ourense) con el objetivo de impulsar el retorno demográfico a las aldeas gallegas a través de un sistema que recuerda al de la popular app de citas.",
      date: "28 Sep",
      year: "2022",
      image:
        "https://media.quincemil.com/imagenes/2022/09/27211626/BeFunky-collage-2022-09-27T191553.489-1706x960.jpg",
      btnClass: "btn",
      url: "https://www.elespanol.com/quincemil/articulos/economia/nace-aldealista-el-tinder-de-los-pueblos-de-galicia-que-busca-dar-visibilidad-al-rural",
    },
    {
      title:
        " Aldealista é unha nova páxina web para promover o turismo rural.  Explicámoscho de xeito #Sinxelo.",
      content:
        "Aldealista é unha nova páxina web para promover o turismo rural.  Explicámoscho de xeito #Sinxelo.",
      date: "27 Sep",
      year: "2022",
      image: "https://i3.ytimg.com/vi/DbkmEzVBdOg/hqdefault.jpg",
      btnClass: "btn",
      url: "https://www.youtube.com/watch?v=DbkmEzVBdOg",
    },
    {
      title:
        "Nace a plataforma dixital Aldealista para darlle visibilidade ao rural",
      content:
        'Aldealista nace nunha aldea que é Castiñeiro, aquí en San Xoán de Río, que eramos cero censados cando saltou o estado de alarma. Hoxe xa somos 6, comeza tamén a crearse un pequeno negocio dun cámping sostible e por tanto imos darlle visibilidade ao que é toda a nosa Galicia rural", conta Juan Carlos Pérez, fundador de Aldealista.',
      date: "26 Sep",
      year: "2022",
      image: "https://www.crtvg.es/files/full/G24recortada.jpg",
      btnClass: "btn",
      url: "https://www.g24.gal/-/nace-a-plataforma-dixital-aldealista-para-darlle-visibilidade-ao-rural",
    },
    {
      title: " Aldealista, una solución digital al abandono rural",
      content:
        "La tercera jornada de la Mobile Week Ourense 2022 acogió ayer la presentación de diversas iniciativas digitales centradas, en su mayoría, en la aplicación de la inteligencia artificial. En el turno de mañana, el economista Juan Carlos Pérez Rodríguez y el director del Innovation Hub de Cesuga, Juan Martín Rodríguez, presentaron su proyecto Aldealista, una plataforma para visibilizar los pueblos y aldeas más desconocidas de toda Galicia. “Queremos hacer visible lo invisible”, recalca Pérez. ",
      date: "18 Sep",
      year: "2022",
      image: "https://i3.ytimg.com/vi/dwfIGeTNm1A/maxresdefault.jpg",
      btnClass: "btn",
      url: "https://www.laregion.es/articulo/ourense/aldealista-solucion-digital-abandono-rural/202209172052581161312.html",
    },
  ];

  const contentRef = useRef(null); // Create a ref

  const itemsPerPage = 6;
  const [currentItems, setCurrentItems] = useState(
    BLOG_DATA.slice(0, 0 + itemsPerPage)
  );

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % BLOG_DATA.length;
    setCurrentItems(BLOG_DATA.slice(newOffset, newOffset + itemsPerPage));

    if (contentRef.current) {
      contentRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      {/* Start Blog  */}
      <section className="section" id="media">
        <Container>
          <Row className="justify-content-center pb-5">
            <Col>
              <div className="section-title text-center mb-4 pb-2">
                <h4 className="title mb-3 main-color">
                  {t("media.mainHeader")}
                </h4>
                <h5 className="mb-3">{t("media.subheaderTitle")}</h5>
                <p className="text-muted para-desc mb-0 mx-auto">
                  {t("media.subheaderTitleText")}
                </p>

                <img
                  src={MediaIMG}
                  className="rounded img-fluid mx-auto d-block"
                  alt=""
                  style={{ height: "500px", marginTop: "40px" }}
                />
              </div>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col>
              <div className="section-title text-center mb-4 pb-2">
                <h5 className="mb-3">{t("media.subheaderClipping")}</h5>
                <p className="text-muted para-desc mb-0 mx-auto">
                  {t("media.subHeaderTextClipping")}
                </p>
              </div>
            </Col>
          </Row>

          <Row ref={contentRef}>
            {currentItems.map((blogPost, index) => (
              <Col lg={4} md={6} className="mt-4 pt-2">
                <Card className="blog blog-primary shadow rounded overflow-hidden border-0">
                  <div className="blog-image position-relative overflow-hidden">
                    <img
                      src={blogPost.image}
                      className="img-fluid"
                      alt=""
                      style={{
                        width: "100%",
                        height: "200px",
                        objectFit: "cover",
                      }}
                    />
                  </div>

                  <CardBody
                    className="content p-0"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      maxHeight: "275px",
                      minHeight: "275px",
                    }}
                  >
                    <div className="pt-2 px-4">
                      <p className="text-muted mt-2 mb-0 main-color-strong">
                        {blogPost.date + " " + blogPost.year}
                      </p>
                    </div>

                    <div className="px-4 pb-4 pt-1" style={{ flexGrow: 1 }}>
                      <a
                        href={blogPost.url}
                        target="_blank"
                        rel="noreferrer"
                        className="h5 title text-dark d-block mb-0"
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {blogPost.title}
                      </a>
                      <p
                        className="text-muted mt-2 mb-0"
                        style={{
                          display: "-webkit-box",
                          WebkitBoxOrient: "vertical",
                          WebkitLineClamp: 5,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {blogPost.content}
                      </p>

                      <div
                        className="mt-3"
                        style={{ position: "absolute", bottom: "30px" }}
                      >
                        <a
                          href={blogPost.url}
                          target="_blank"
                          rel="noreferrer"
                          className="link text-dark"
                        >
                          {t("media.readMore")}{" "}
                          <i className="uil uil-arrow-right"></i>
                        </a>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))}
            <Col lg={12} className="pt-5 mt-3">
              {/*<Sidebar />*/}
              {/* Use ReactPaginate */}
              <ReactPaginate
                breakLabel="..."
                nextLabel=" >"
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                marginPagesDisplayed={2}
                pageCount={Math.ceil(BLOG_DATA.length / itemsPerPage)}
                previousLabel="< "
                renderOnZeroPageCount={null}
                containerClassName={"react-paginate"}
                activeClassName={"selected"}
                disabledClassName={"disabled"}
              />
            </Col>
          </Row>
        </Container>
      </section>
      {/* End Blog  */}
    </>
  );
}
