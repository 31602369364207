export const TRANSLATIONS_GL = {
  menu: {
    about: "A nosa historia",
    services: "Que facemos?",
    ecosystem: "Aldealista Ecosystem",
    media: "Prensa e medios",
    app: "A App",
    contact: "Contacto",
    language: "Idioma",
  },
  about: {
    bannerTitle: "Aldealista: facemos visible o invisible",
    bannerSubtitle:
      "Rural Match contra o despoboamento: conectando o presente co futuro do campo",
    bannerFeatures1: "Conectamos ás persoas coas aldeas",
    bannerFeaturesSub1:
      "Facilitamos a integración de novos residentes en comunidades rurais, ofrecendo oportunidades para vivir, traballar e prosperar.",
    bannerFeatures2: "Somos o movemento do Renacemento Rural",
    bannerFeaturesSub2:
      "Revitalizamos as áreas rurais, promovendo un desenvolvemento sostible que atrae a persoas, empresas e talento cara a un futuro próspero no campo.",
    bannerFeatures3: "Porque as aldeas non poden desaparecer",
    bannerFeaturesSub3:
      "Cada aldea conta unha historia. Loitamos para que esa historia siga viva, impulsando solucións que aseguren a súa supervivencia e crecemento.",
    mainTitle: "A nosa historia",
    mainSubtitle: "Nacemos dun desexo, dun soño, dun encargo.",
    header1: "Fai algo para salvar estas aldeas",
    text1:
      "Estas foron as palabras que a súa avoa, con 102 anos de sabedoría, lle dixo antes de marchar. Era plena pandemia, e Juan Carlos Pérez, entón residente en Noruega, decidiu regresar á súa aldea en Galicia para fundar Aldealista. Non foi unha decisión calquera, foi un chamado: salvar o rural, revitalizar o que parecía condenado ao esquecemento.",
    text2:
      "Aldealista non é só unha plataforma; é o motor que impulsa o renacemento do ecosistema rural. Conectamos as potencialidades de cada territorio, dando valor aos seus recursos e atraendo a persoas de todas partes do mundo. O noso obxectivo: que a xente elixa quedar, traballar e vivir nestes lugares que aínda teñen tanto que ofrecer.",
    text3:
      "Innovación, creatividade e un impacto real son o corazón das nosas propostas. Porque cremos que o futuro tamén se constrúe desde o rural.",
    text4:
      "Hoxe, Aldealista está presente en 52 países, unindo a persoas e comunidades. Estamos creando un novo futuro rural, cheo de oportunidades e vida.",
    ourTeam: "O noso equipo",
    ourTeamSubtitle: "Impulsores do Renacemento Rural",
    team1: "Juan Carlos Pérez",
    team1Sub:
      "CEO - Socio fundador Grupo Rural Manager SL e VPF Scandinavia AS - Desenvolvemento IT",
    team2: "Juan Martín",
    team2Sub: "Socio fundador Grupo Rural Manager SL - Controller Aceleradora",
    team3: "María Valcarcel",
    team3Sub: "Marca",
    team4: "Migue Gil",
    team4Sub: "Plataforma Booking - Subscripción",
    team5: "Rocío Pérez",
    team5Sub: "Marketing Dixital",
  },
  services: {
    mainTitle: "Que facemos?",
    mainSubtitle:
      "Loitamos contra o despoboamento: o noso compromiso co mundo rural",
    title1: "As aldeas invisibles: unha realidade que non podemos ignorar",
    text1:
      "En España, dos 8.131 concellos, 6.827 teñen menos de 5.000 habitantes. Son aldeas invisibles, pero esenciais.",
    text2:
      "Sabías que o 16% da poboación rural alimenta ao outro 84% que vive nas cidades? Esta é a paradoxa dun mundo que depende do campo, pero que o deixa atrás.",
    text3:
      "Nos concellos de menos de 5.000 habitantes, un preocupante 44% das vivendas están baleiras ou só se utilizan como segundas residencias. O campo baleirase mentres a cidade medra.",
    text4:
      "Máis do 80% da poboación vive en menos do 20% do territorio. Este desequilibrio invítanos a repensar o futuro das nosas terras rurais.",
    text5: "Non podemos esperar máis.",
    text6: "É o momento de impulsar o rural.",
  },
  ecosystem: {
    headerTitle: "Aldealista Ecosystem",
    headerSubtitle: "A clave para o renacemento rural",
    text1:
      "Aldealista Ecosystem é máis que unha plataforma. É unha iniciativa completa que conecta solucións innovadoras coas necesidades do mundo rural. A través de servizos interconectados e estratexias efectivas, estamos abordando tanto os desafíos como as oportunidades que existen nestas comunidades.",
    text2:
      "O noso obxectivo non é só atraer novos residentes e turistas ás áreas rurais, senón transformar estas rexións cun enfoque integral. Promovemos o desenvolvemento económico, impulsamos a creación de emprego, e traballamos en mellorar infraestruturas e servizos esenciais para construír un futuro rural vibrante.",
    subHeader: "Que buscamos lograr?",
    subHeaderText1:
      "Fortalecer a economía rural, impulsando novas oportunidades.",
    subHeaderText2:
      "Mellorar a calidade de vida de quen elixe vivir e traballar nestas áreas.",
    subHeaderText3:
      "Fomentar un desenvolvemento sostible e equilibrado que beneficie tanto ás persoas como ao medio ambiente.",
    text3:
      "Con estes cinco piares, Aldealista Ecosystem é a plataforma que conecta, descubre e fai medrar ás comunidades rurais, levando progreso onde máis se necesita.",
  },
  media: {
    mainHeader: "Prensa",
    subheaderTitle: "O impacto mediático en 2023: unha pegada significativa",
    subheaderTitleText:
      "No ano 2023, o valor estimado do impacto mediático de Aldealista Ecosystem acadou cifras sorprendentes: máis de 850.920 € en medios impresos e dixitais, e 106.603 € en Radio e Televisión. Estas cifras subliñan o alcance e a relevancia da nosa misión na revitalización do mundo rural.",
    subheaderClipping: "Clipping de prensa",
    subHeaderTextClipping:
      "Selección de novas coa aparición de Aldealista, tanto en medios nacionais como internacionais, cunha ligazón directa á fonte orixinal no seu idioma.",
    subHeaderAwards: "Recoñecementos e logros que impulsan a nosa misión",
    subHeaderAwardsText1:
      "Xullo 2023: Finalistas na categoría App na prestixiosa IV Edición dos European Technology Awards, celebrada no icónico Hotel Ritz de París.",
    subHeaderAwardsText2:
      "Novembro 2023: A Axencia Galega de Innovación (GAIN) recoñeceunos como parte da súa selección de casos de éxito innovadores.",
    subHeaderAwardsText3:
      "22 de Novembro: Gañadores do programa “Que Vivan os Pobos”, unha iniciativa para startups comprometidas co desenvolvemento rural, organizada por Yoigo e Unlimited.",
    subHeaderAwardsText4:
      "Mellor Empresa Innovadora 2023: Un galardón outorgado pola Confederación de Empresarios de Ourense, destacando o noso enfoque innovador.",
    subHeaderAwardsText5:
      "MadBlue Impact Summit: Gañadores na categoría People & Social Impact, reafirmando o noso compromiso cun impacto social positivo.",
    readMore: "Ler máis",
  },
  app: {
    headerTitle: "A App",
    headerSubtitle: "Descobre a túa aldea",
    text1:
      "Aldealista é a aplicación da España Rural Viva, que chega para empoderar e converterse na Resistencia do Rural. Desliza e descobre a túa aldea!",
    featuresTitle: "Como funciona?",
    featuresSubtitle: "Domina en tres sinxelos pasos a dinámica da app:",
    featuresText1: "Selecciona a túa área",
    featuresSubtext1:
      "Dinos que punto concreto da xeografía queres explorar, ou selecciona todo o territorio se estás indeciso",
    featuresText2: "Desliza",
    featuresSubtext2:
      "Desliza á esquerda ou á dereita como nas aplicacións de citas que dis que nunca utilizaches, o noso algoritmo intelixente aprenderá das túas preferencias",
    featuresText3: "Match",
    featuresSubtext3:
      "Cremos que atopamos unha aldea perfecta para ti! Bótalle un ollo ao seu perfil, descubre o que ten para ofrecer e prepárate para visitala",
    screenshotTitle: "Psst, bótalle un ollo á app",
    screenshotSubtitle:
      "Traemos unhas capturas a modo de sneak peek para que te vaias familiarizando con Aldealista.",
    metrics: "Métricas",
    metricsSubtitle: "O noso impacto en números, desde xaneiro de 2023",
    users: "Usuarios",
    matches: "Matches",
    swipes: "Swipes",
  },
  contact: {
    header: "Contacto",
    headerSubtitle:
      "Estamos a crear un futuro rural cheo de oportunidades, e queremos que formes parte del. Conéctate con nós e descobre como podes vivir ou traballar nas aldeas que necesitan a túa enerxía!",
    text1:
      "Gustaríache vivir no rural? Estamos a axudar a persoas como ti a atopar o seu fogar ideal en áreas rurais que necesitan novos residentes.",
    text2:
      "O Renacemento Rural tamén é unha oportunidade laboral. Existen moitas formas en que o teu talento pode contribuír ao desenvolvemento e crecemento das aldeas.",
    text3:
      "Estamos aquí para axudarche, xa sexa que teñas consultas, que busques un novo fogar nunha aldea, ou queiras formar parte dunha comunidade en crecemento. Contacta connosco hoxe!",
    name: "Nome",
    email: "Correo electrónico",
    topic: "Asunto",
    message: "Mensaxe",
    send: "Enviar",
    metrics: "Máis de 5000 solicitudes recibidas para repoboar o rural",
  },
  cta: {
    text1: "Aldealista é unha marca rexistrada do Grupo Rural Manager SL",
    text2: "Descubre máis sobre nós na nosa web",
  },
  errors: {
    errorEmpty:
      "Comproba os teus datos: nome, email, asunto e mensaxe son obrigatorios.",
    errorGeneric: "Algo fallou, ténteo máis tarde.",
    errorEmail: "O formato do correo é incorrecto.",
    sent: "Formulario enviado correctamente.",
  },
  footer: {
    partners: "Os nosos colaboradores",
    org: "Organismos",
    companies: "Empresas",
    foundations: "Fundacións",
    main: "Sexas unha persoa nova e activa, madura e interesante, ou vivas enfadado co mundo, temos un perfil na túa rede social favorita. Tamén podes contactarnos no noso correo electrónico: ",
    copyright: "Todos os dereitos reservados",
    available: "Dispoñible en:",
  },
  month: {
    dec: "Dec",
    jan: "Xan",
    feb: "Feb",
    mar: "Mar",
    apr: "Abr",
    may: "Mai",
    jun: "Xuñ",
    jul: "Xul",
    aug: "Ago",
    sep: "Set",
    oct: "Out",
    nov: "Nov",
  },
};
